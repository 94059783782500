import { EditableList } from "../editableList";
import { Box, Stack, Typography } from "@mui/material";
import { ObjectFeatureForm, UNMODIFIED_VALUE } from "@grenton/gm-logic";
import { ApiItemListProps, ApiListItem, renderItemListLabel } from "../common";
import { schema } from "@grenton/gm-common";
import { PropertyValueField } from "../propertyValueField";
import { textOverflowEllipsis } from "@grenton/design-system";

type DefaultValueChangeHandler = (id: string, value: schema.PropertyValue) => void;

type Props = ApiItemListProps<ObjectFeatureForm> & {
    onDefaultValueChange: DefaultValueChangeHandler
}

function FeatureItemRenderer({ item, shrunk, onDefaultValueChange }: { item: ApiListItem<ObjectFeatureForm>, shrunk: boolean, onDefaultValueChange: DefaultValueChangeHandler }) {
    
    const defaultValue = item.form.config.value
    return (
        <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center', height: 44, overflow: 'visible' }}>
            <Typography variant="l" sx={{ flexGrow: 1, width: shrunk ? 'auto' : 250, minWidth: shrunk ? 0 : 250, overflow: 'hidden', textOverflow: 'ellipsis' }}>{renderItemListLabel(item.form.spec)}</Typography>
            {!shrunk && <PropertyValueField minWidth={250} onClick={e=>e.stopPropagation()} label="Default" type={item.form.spec.type} value={defaultValue === UNMODIFIED_VALUE ? null : defaultValue} onChange={(value)=>onDefaultValueChange(item.id,value)}/>}
            {!shrunk && <Box sx={{overflow:'hidden', height:'100%', display:'flex',alignItems:'center'}}><Typography variant="s" sx={{ flexGrow: 1, ...textOverflowEllipsis(2)}}>{item.form.spec.description}</Typography></Box>}
        </Stack>
    )
}

export function FeatureList({items, editedItemId, onEdit, onAdd, onDelete, onDefaultValueChange, children}: Props) {
    
    // can be moved up
    const onEditItem = (id: string) => {
        onEdit && onEdit(id)
    }

    const listItems : ApiListItem<ObjectFeatureForm>[] = Object.entries(items).map(([id,form]) => ({ 
        id, 
        selectable: form.editable, 
        removeable: form.editable,  
        form
    }))

    return <EditableList
        shrunkWidth={250}
        selectedItem={editedItemId}
        onSelectItem={onEditItem}
        onDeleteItem={onDelete}
        onNewItem={onAdd}
        items={listItems}
        renderer={FeatureItemRenderer}
        rendererProps={{onDefaultValueChange}}
        >
        {children}
    </EditableList>

}

