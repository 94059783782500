import { KeyMap, OUTLET_OBJECT, isParentOrObject, ANONYMOUS_CONTROLLER_SUFFIX } from '@grenton/gm-common';
import { notEmpty } from '@grenton/utils';
import { ProjectObjectImpl, ObjectResolver, OutletConfigImpl, OutletImpl } from '@grenton/gm-logic';
import { eventNodes } from './eventNodes';
import { methodNode } from './methodNode';
import { outletNode } from './outletNode';
import { ProjectTreeItem, ProjectTreeItemObjectData, ProjectTreeItemData, ProjectTreeItemType } from '@grenton/gm/editor/components/projectComponentTree';
import { sortTreeNodes } from '../../../../../../ui/sortTreeNodes';
import { GIconName } from '@grenton/gm/ui/icons';
import { concatTreeItemId } from './id';

/**
 * for each object we
 * - if object is scriptable, render method nodes,
 * - render its outlets (except "parent" and "object" outlets)
 * -    if outlet has static references, resolve them and render as objectNode
 * -    if outlet has no static references, render them as outletNode
 * - if this object has an anonymous controller, render controller's "object" outlet as outletNode
 */
export function objectNode(
    multiSelectMode: boolean,
    parentItemId: string,
    more: KeyMap,
    path: string[], // path can be [objectId] or [parentObjectId,childObjectId] etc
    object: ProjectObjectImpl,
    iconResolver: (object: ProjectObjectImpl) => GIconName | null,
    objectResolver: ObjectResolver,
    rootTag?: string,
): ProjectTreeItem<ProjectTreeItemObjectData> {
    const id = concatTreeItemId(parentItemId, object.uuid);

    const _methodNodes = multiSelectMode
        ? []
        : object.impl.type === 'script'
          ? Object.values(object.api.methods)
                .map((method) => methodNode(id, [...path, method.id], object, method))
                .sort(sortTreeNodes)
          : [];

    const _outletNodes: ProjectTreeItem<ProjectTreeItemData>[] = Object.values(object.api.outlets)
        .filter((outlet) => !isParentOrObject(outlet.id))
        .map((outlet) => {
            const outletConfig = object.init.outlets[outlet.id];

            // here we treat all static refs as 'subobjects'.
            // it is compatible with 2.0, but I do not like the fact that we do not show
            // regular outlet event handlers. with current approach, I need to program each smartpanel button
            // separately, because I do not have access to smartpanel.buttons.onClick handler on UI.
            if (outletConfig?.isStatic) {
                // static child objects
                return (
                    outletConfig.staticRefs
                        .map(objectResolver)
                        .filter(notEmpty)
                        // here is additional check for parent-child relation
                        .filter((child) => child.parent === object.uuid)
                        .map((child, index) =>
                            objectNode(
                                multiSelectMode,
                                id,
                                more,
                                [...path, createIndexedOutletId(outlet, outletConfig, index)],
                                child,
                                iconResolver,
                                objectResolver,
                                rootTag,
                            ),
                        )
                );
            } else if (!multiSelectMode) {
                // regular outlet
                return [outletNode(id, more, [...path, outlet.id], object, outlet)];
            } else {
                // with multiselect enabled, hide regular outlets
                return [];
            }
        })
        .flat();
    //.sort(sortTreeNodes); <- we need to preserve order of static refs (subobjects). TBD: we can sort other outlets

    // eventHolder is this object (if it is script type) or its anonymous controller (for non-script objects)
    // generally all non-script objects should have anonymous controller - they are added during import
    const eventHolder = multiSelectMode ? null : object.impl.type === 'script' ? object : objectResolver(`${object.uuid}${ANONYMOUS_CONTROLLER_SUFFIX}`);

    const _eventNodes = !eventHolder
        ? []
        : eventHolder.api.outlets[OUTLET_OBJECT]
          ? eventNodes(id, more, [eventHolder.uuid, OUTLET_OBJECT], eventHolder, object, eventHolder.api.outlets[OUTLET_OBJECT].api)
          : eventNodes(id, more, [object.uuid], object, object, object.api);

    const item: ProjectTreeItem<ProjectTreeItemObjectData, ProjectTreeItemData> = {
        highlight: true,
        id,
        icon: iconResolver(object) || 'unknown',
        label: object.label,
        sortKey: object.label,
        rootClassName: 'object-node',
        data: {
            type: ProjectTreeItemType.OBJECT,
            path: path,
            objectId: object.uuid,
            rootTag,
            assignedToRootTag: !rootTag || object.hasTag(rootTag),
        },
        children: [..._methodNodes, ..._outletNodes, ..._eventNodes],
    };
    return item;
}

export function createIndexedOutletId(outlet: OutletImpl, outletConfig: OutletConfigImpl, index: number) {
    return outletConfig.staticRefs.length > 1 ? `${outlet.id}[${index + 1}]` : outlet.id;
}
