import { useDerivedState } from '@grenton/utils';
import { ProjectImpl } from '@grenton/gm-logic';
import { useService } from '../../providers';

type ExtractFn<V> = (p: ProjectImpl) => V;

export function useProject(): [ProjectImpl];
export function useProject<V>(fn: ExtractFn<V>): [V];

export function useProject<V>(fn?: ExtractFn<V>): [V | ProjectImpl] {
    const { projectHolder } = useService();
    return useDerivedState(projectHolder.project, (p) => {
        if (!p) throw new Error('project not loaded');
        return fn ? fn(p) : p;
    });
}

/**
 * return derived state from project or default value if project is not loaded
 * @param fn
 * @param defaultValue
 * @returns
 */
export function useProjectOr<V>(fn: ExtractFn<V>, defaultValue: V): [V] {
    const { projectHolder } = useService();
    return useDerivedState(projectHolder.project, (p) => {
        return p ? fn(p) : defaultValue;
    });
}
