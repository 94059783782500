import { GRichTreeView, GTreeItemRendererProps, ModuleLabel, TagLabel, icons } from "@grenton/design-system";
import { ObjectsByModule } from "./types";
import { ProjectImpl, ProjectObjectImpl, filterAllowedFunctionalTypes, parseTag } from "@grenton/gm-logic";
import { isParentOrObject, notEmpty } from "@grenton/gm-common";
import { sortTreeNodes } from "@grenton/gm/ui/sortTreeNodes";
import { Box, ButtonBase, IconButton, MenuItem, Select, Stack, SxProps, TextField } from "@mui/material";
import { SideDialogTagSelector } from "@grenton/gm/editor/components";
import { useEffect, useState } from "react";
import { useDispatcher, useProject } from "@grenton/gm/ui";
import { ObjectQuickEditCommand, ObjectQuickEditForm } from "@grenton/gm-logic";


function TextFieldStateful({sx, value, onBlur}:{sx?:SxProps,value:string, onBlur:(e:React.FocusEvent, value:string)=>void}) {

    const [_value, _setValue] = useState(value)
    useEffect(()=>{
        _setValue(value)
    },[value])

    return <TextField sx={sx} 
        value={_value} 
        onChange={e=>_setValue(e.target.value)}
        onBlur={e=>onBlur(e, _value)}
        onClick={e=>e.stopPropagation()}/>
}

function PreparationItemRenderer({item, onClick}:GTreeItemRendererProps<PreparationItem>){
    const [tags] = useProject(p=>p.tags)
    
    function render(item:PreparationItem){
        switch (item.data.type) {
            case 'module': return <Stack sx={{flexGrow:1,p:1, gap:1,flexDirection:'row', alignItems:'center'}}>
                <ModuleLabel module={item.data} />
                {item.data.id}
            </Stack>
            case 'object': return <Stack sx={{flexGrow:1,p:1, gap:1,flexDirection:'row', alignItems:'center'}}>
                <Box sx={{minWidth:100}}/>
                <TextFieldStateful sx={{maxWidth:300,minWidth:300}} value={item.data.form.label||''} onBlur={(e,value)=>onClick(e as any, {type:'label', label:value})}/>
                <Select sx={{minWidth:200,maxWidth:200}} value={item.data.form.functionalType||''} onClick={e=>e.stopPropagation()} onChange={e=>onClick(e as any, {type:'functional-type',functionalType:e.target.value})}>
                    <MenuItem value="">-</MenuItem>
                    {item.data.functionalTypes.map(ft=><MenuItem key={ft} value={ft}>{ft}</MenuItem>)}
                </Select>
                <Stack sx={{gap:1, flexDirection:'row', flexWrap:'wrap'}}>
                    {item.data.form.tags.map(tag=><ButtonBase key={tag} onClick={e=>onClick(e,{type:'add-tag'})}><TagLabel color={tags.getColor(parseTag(tag).category)} label={tag}/></ButtonBase>)}
                </Stack>
                <IconButton size="small" onClick={e=>onClick(e,{type:'add-tag'})}><icons.Add/></IconButton>
            </Stack>
        }
    }

    return render(item)
}

type PreparationItem = {
    id: string;
    label: string;
    icon: string | null;
    sortKey: string;
    data: {   
            type:'module',
            id:string,
            ref:string
        } |
        {
            type:'object',
            form: ObjectQuickEditForm,
            functionalTypes:string[]
        };
    children?: PreparationItem[];
}


/**
 * this is a performance killer !
 * @param project 
 * @param object 
 * @returns 
 */
const functionalTypes = (project:ProjectImpl, object:ProjectObjectImpl)=>{
    const baseProtocols = object.api.api
    return project.firmware.getFunctionalTypes(baseProtocols)
    .map(dt => dt.id)
    .filter(filterAllowedFunctionalTypes(project, object.impl.componentRef))  
}

function objectNode(parentItemId:string, object:ProjectObjectImpl, project:ProjectImpl, objectResolver: (id: string) => ProjectObjectImpl | undefined){

    const id = `${parentItemId}/${object.uuid}`;

    const children: PreparationItem[] = Object.values(object.api.outlets)
    .filter((outlet) => !isParentOrObject(outlet.id))
    .map((outlet) => {
        const outletConfig = object.init.outlets[outlet.id];
        if (outletConfig?.isStatic) {
            // static child objects
            return outletConfig.staticRefs
                .map(objectResolver)
                .filter(notEmpty)
                .map((child) => objectNode(id, child, project, objectResolver));
        } else {
            // with multiselect enabled, hide regular outlets
            return [];
        }
    })
    .flat()
    .sort(sortTreeNodes);

    const item:PreparationItem = {
        id,
        label: object.label,
        icon: null,
        sortKey: object.label,
        data: {
            type:'object' as const,
            form : {
                id:object.uuid,
                label:object.label,
                tags:object.tags.selected,
                functionalType: object.userType
            },
            functionalTypes: functionalTypes(project, object)
        },
        children
    }
    return item
}

export function NewVer({objectsByModule,project}:{objectsByModule:ObjectsByModule[], project:ProjectImpl}){

    const [tagSelector, setTagSelector] = useState<ObjectQuickEditForm | null>(null);
    const dispatcher = useDispatcher();

    const items:PreparationItem[] = objectsByModule.map((mod) => {
        return {
            id: mod.id,
            label: mod.ref,
            icon: null,
            sortKey: mod.id,
            data: {
                type:'module' as const,
                id:mod.id,
                ref:mod.ref
            },
            children: mod.objects?.map(object=>objectNode(mod.id, object, project,  project.objectResolver))
        }
    }).sort(sortTreeNodes);


    const onItemClick = (_:React.MouseEvent, item: PreparationItem, data:any) => {
        if (item.data.type === 'object') {
            switch (data.type) {
                case 'add-tag': {
                    setTagSelector(item.data.form)
                    break;
                }
                case 'functional-type': {
                    const form = {...item.data.form, functionalType: data.functionalType}
                    dispatcher(new ObjectQuickEditCommand({form}))
                    break;
                }
                case 'label': {
                    const form = {...item.data.form, label: data.label}
                    dispatcher(new ObjectQuickEditCommand({form}))
                    break;
                }
                // probably unnecessary. deleting should not be simpler than adding
                // case 'delete-tag': {
                //     const form = {...item.data.form, tags: item.data.form.tags.filter(tag=>tag!==data.tag)}
                //     dispatcher(new ObjectQuickEditCommand({form}))
                //     break;
                // }
            }
        }
    }

    const onTagChangesApplied= () => {
        if (tagSelector) {
            dispatcher(new ObjectQuickEditCommand({form:tagSelector}))
            setTagSelector(null)
        }
    }

    return <>
        <GRichTreeView 
            sx={{'&>li':{border:'1px solid #ddd',margin:1}}}
            onItemClick={onItemClick}
            selectedItems={[]} 
            itemChildrenIndentation={0}
            items={items}
            renderer={PreparationItemRenderer}></GRichTreeView>
        <SideDialogTagSelector 
            open={Boolean(tagSelector)} 
            align="end"
            // here we should apply change
            onClose={onTagChangesApplied }
            tags={project.tags.export()} 
            selected={tagSelector?.tags||[]}
            onChange={(tags: string[])=>{if (tagSelector) setTagSelector({...tagSelector, tags})}}/>
    </>
}