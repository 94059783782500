import { GTreeItemRendererProps, ModuleLabel } from "@grenton/design-system"
import { ActionIconButton, ObjectTreeItemContent, ObjectTreeItemRoot } from "@grenton/gm/shared/components/objectTreeItem"
import { IconConstructor, icons } from "@grenton/gm/ui/icons"
import { Add, Clear, MoreHoriz } from "@mui/icons-material"
import { HWTreeItemType, HWTreeItem, CLU_ACTION_ADD_OR_REMOVE } from "./types"
import { OnlineIndicator } from "./OnlineIndicator"
import React from "react"
import { ActionIconToggleButton } from "@grenton/gm/shared/components/objectTreeItem/ActionIconToggleButton"
import { DynamicLinkButton } from "../components/DynamicLinkButton"
import { Button } from "@mui/material"



export function hardwareTreeRenderer({item, onClick}: GTreeItemRendererProps<HWTreeItem>): JSX.Element {
    
    function content() {

        const data = item.data

        switch (data.type) {
                case HWTreeItemType.CLUSTER: {
                    return <ObjectTreeItemContent 
                        emphasis={data.local}
                        label={`Project ${data.projectId}`} 
                        hint={`version ${data.projectRevision}`}/>
                }
                case HWTreeItemType.CLU: {
                        const label = <><OnlineIndicator online={data.clu.online}/>CLU {item.label}</>
                        const action = data.clu.imported ? <ActionIconToggleButton selected={false} icon={<Clear/>} onClick={e=>onClick(e, CLU_ACTION_ADD_OR_REMOVE)}/> : 
                            <Button size="small" endIcon={<Add/>} onClick={e=>onClick(e, CLU_ACTION_ADD_OR_REMOVE)}>Join</Button> 
                        return <ObjectTreeItemContent label={label} action={action}/>
                }
                case HWTreeItemType.MODULE: {
                        const label = <><OnlineIndicator online={data.module.online}/><ModuleLabel module={data.module}/></>
                      //  const action = data.projModule ? <ActionIconToggleButton sx={{zIndex:11}} selected={Boolean(data.linking)} icon={<LinkOff/>} onClick={e=>onClick(e,'unlink')}/> : <ActionIconToggleButton sx={{zIndex:11}} selected={Boolean(data.linking)} icon={<AddLink/>} onClick={e=>onClick(e,'link')}/> 
                        const action = <DynamicLinkButton disabled={item.disabled} belongsTo="hardware" moduleId={data.module.id} linked={Boolean(data.projModule)} onClick={e=>onClick(e,'link')}/>
                        const hint = data.projModule ? `${data.module.id} \u2194 ${data.projModule.uuid}` : data.module.id
                        return <ObjectTreeItemContent label={label} action={action} hint={hint}/>
                }
                case HWTreeItemType.OBJECT: {
                    return <ObjectTreeItemContent label={item.label} hint={data.linkedTo?.label} />
                }
                        
            default:
                return <ObjectTreeItemContent label={item.label} action={<ActionIconButton icon={<MoreHoriz/>} onClick={()=>{}} />} />
        }
    }


    let icon:React.ReactNode = null
    if (item.icon) {
        const ResolvedIcon:IconConstructor = icons[item.icon]
        icon = <ResolvedIcon fontSize="inherit"/>
    }


    return <ObjectTreeItemRoot icon={icon} onClick={onClick}>{content()}</ObjectTreeItemRoot>

    

    
}
