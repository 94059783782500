import { ActionIconToggleButton } from "@grenton/gm/shared/components/objectTreeItem/ActionIconToggleButton"
import { useContext } from "react"
import { SelectionContext } from "../selectionContext"
import { SelectedModule } from "../types"
import { PaneBackdropZIndex } from "./PaneBackdrop"
import { ConnectorConnected, ConnectorDisconnected } from "@grenton/gm/ui/icons/customIcons"

type Props = {
    linked:boolean,
    disabled?:boolean,
    belongsTo:SelectedModule["belongsTo"],
    moduleId:string,
    onClick:(e:React.MouseEvent)=>void
}

export function DynamicLinkButton({onClick,linked,moduleId,belongsTo,disabled}:Props) {
    const selection = useContext(SelectionContext)
    const selected = linked || selection?.selection==='module'&&selection.belongsTo===belongsTo&&selection.uuid===moduleId
    const zIndex = selected?PaneBackdropZIndex+1:0
    return <ActionIconToggleButton color={linked?'secondary':'primary'} disabled={disabled} sx={{zIndex}} selected={selected} icon={(linked ?  <ConnectorConnected/> : <ConnectorDisconnected/>)} onClick={onClick}/>
}