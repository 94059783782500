import {objectSelectorTreeModel} from './model';
import { ProjectTreeItemType } from "@grenton/gm/editor/components/projectComponentTree";
import { KeyMap } from "@grenton/gm-common";
import { ProjectImpl } from "@grenton/gm-logic";
import { ProjectComponentTree2 } from "@grenton/gm/editor/components";
import { outletObjectSelectorRenderer } from "./renderer";


export type Props = {
    project: ProjectImpl;
    selected: KeyMap;
    allowedApis: string[];
    onChange: (id: string) => void;
};

export function OutletObjectSelectorTree({project, selected, allowedApis}: Props) {

    const multiSelect: { [key: string]: boolean } = {}
    const items = objectSelectorTreeModel({project, allowedApis})

    items.forEach(n => {
        if (n.data.type === ProjectTreeItemType.OBJECT && n.data.active) {
            multiSelect[n.id] = selected[n.data.objectId] || false
        }
        (n.children || []).forEach(n2 => {
            if (n2.data.type===ProjectTreeItemType.OBJECT && n2.data.active) {
                multiSelect[n2.id] = selected[n2.data.objectId] || false
            }
        })
    })


    return (
        <ProjectComponentTree2
            items={items}
            defaultExpanded={['tag:$$root', 'type:$$root']}
            selectableItems={{}}
            selectedItems={[]}
            itemRenderer={outletObjectSelectorRenderer} 
            onItemClick={(_e, _item, _data) => {
                
            }}/>
    )

}