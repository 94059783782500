import {Button, Checkbox, FormControlLabel, IconButton, TextField, Typography} from "@mui/material";
import {EnumSelector} from "../enumSelector";
import {schema} from "@grenton/gm-common";
import {Stack} from "@mui/system";
import type {MethodEditProps} from './types';
import { ObjectMethodForm } from "@grenton/gm-logic";
import { renderItemListLabel } from "../common";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { FieldErrorMessages } from "../misc";
import { InlineEditPane } from "../misc/InlineEditPane";
import { newApiItemId } from "@grenton/gm-logic/src/project/id";

/**
 * this FORM is used in two ways - as a standalone form invoked directly from MainEditor and as a subform of ControllerForm.
 */
export function MethodEdit({meta, form, errors, onChange, onClose}: MethodEditProps) {
   
    const applyChanges = (changes: Partial<ObjectMethodForm>) => {
        const updated = {...form, ...changes}
        onChange(updated)
    }

    const addMethodParam = () => {
        applyChanges(
            {...form, spec:{...form.spec, params:[...form.spec.params||[], {id:newApiItemId(),label:"", type:schema.PROPERTY_TYPE_VOID}]}}
        )
    }

    const applyParamChanges = (i: number, changes: Partial<schema.MethodParam>) => {
        const params = [...form.spec.params||[]]
        const param = params[i]
        if (!param) return
        params[i] = {...param, ...changes}
        applyChanges({spec:{...form.spec, params}})
    }

    const removeParam = (i: number) => {
        const params = [...form.spec.params||[]]
        params.splice(i, 1)
        applyChanges({spec:{...form.spec, params}})
    }

    return (
            <InlineEditPane onClose={onClose}>
                <TextField
                    error={!errors.field('label')}
                    helperText={<FieldErrorMessages errors={errors.field('label')}/>}
                    value={form.spec.label||''}
                    disabled={meta.readOnly}
                    label="Name" 
                    variant="outlined"
                    onChange={(e) => applyChanges({spec: {...form.spec, label: e.target.value}})}/>

                <TextField
                    fullWidth
                    error={!errors.field('description')}
                    helperText={<FieldErrorMessages errors={errors.field('description')} />}
                    value={form.spec.description || ""}
                    label="Description" 
                    variant="outlined"
                    onChange={(e) => applyChanges({ spec: { ...form.spec, description: e.target.value } })} />

                <FormControlLabel
                    label="Scene"
                    control={
                        <Checkbox
                            checked={form.spec.scene||false} disabled={meta.readOnly}
                            onChange={(_, scene) => applyChanges({spec:{...form.spec, scene}})}/>
                    }
                />

            {!form.spec.scene &&
                <>
                <EnumSelector
                    label="Returns"
                    disabled={meta.readOnly}
                    value={form.spec.result || ''}
                    options={meta.returnTypes.map(t => ({name: t, value: t}))}
                    onChange={val => applyChanges({spec:{...form.spec, result: val}})}/>
                    <Typography variant="m">Parameters</Typography>
                {form.spec.params?.map((p, i) => (
                        <Stack key={i} sx={{flexDirection:'row', alignItems:'center', gap:1}}>
                            <TextField
                                error={!errors.field(`params[${i}]`)}
                                helperText={<FieldErrorMessages errors={errors.field(`params[${i}]`)}/>}
                                value={renderItemListLabel(p,"")}
                                disabled={meta.readOnly}
                                label="Name" 
                                variant="outlined"
                                onChange={(e) => applyParamChanges(i, {label: e.target.value})}/>

                            <EnumSelector
                                label="Type"
                                value={p.type || ''}
                                disabled={meta.readOnly}
                                options={meta.paramTypes.map(t => ({name: t, value: t}))}
                                onChange={type => applyParamChanges(i, {type})}/>

                            <IconButton
                                size="small" 
                                onClick={() => removeParam(i)}><RemoveCircleOutline/></IconButton>
                        </Stack>
                    )
                )}

                <Button
                    color="white" 
                    size="small" 
                    disabled={meta.readOnly} 
                    onClick={addMethodParam} 
                    endIcon={<AddCircleOutline/>}>Add Parameter</Button>
                </>
            }
        </InlineEditPane>
    )
}
