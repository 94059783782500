import {ButtonBase} from "@mui/material";
import type { MouseEvent, PropsWithChildren } from 'react';
import { grentonColors } from "../colors";
import { sizeRem, sizes } from "../size";

type PseudoInputProps = PropsWithChildren<{
    onClick: (e: MouseEvent) => void;
    disabled?: boolean;
    placeholder?: string
}>;

export function GPseudoInput({placeholder, disabled, children, onClick}: PseudoInputProps) {
    return (
        <ButtonBase
            disabled={disabled}
            disableRipple={true}
            sx={{
                // TODO focused state
                margin: 0,
                fontSize: 16,
                borderWidth:1,
                borderStyle: "solid",
                borderColor: grentonColors.grey[400],
                padding: 1,
                borderRadius: 0,
                justifyContent: "start"
            }}
            onClick={onClick}>
            {!children && placeholder && <label style={{fontSize:sizeRem(sizes.M), color:grentonColors.Font_Placeholder}}>{placeholder}</label>}
            {children}
        </ButtonBase>
    )
}