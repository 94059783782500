import {SyntheticEvent, useMemo, useState} from "react";
import {KeyMap} from "@grenton/gm-common";
import {filterNodes} from "./utils";
import { ProjectTreeItem, ProjectTreeItemData, ProjectTreeItemType, ProjectTreeFilterResult, ProjectTreeFilterFunction } from "../../../projectComponentTree";
import { ProjectComponentTree2 } from "../../../projectComponentTree2/ProjectComponentTree2";
import { mainObjectTreeModel } from "./mainObjectTreeModel";
import { mainObjectTreeRenderer } from "./mainObjectTreeRenderer";
import { ErrorBoundary } from "react-error-boundary";
import { ProjectImpl } from "@grenton/gm-logic";
import { ObjectMultiSelection } from "@grenton/gm/editor/utils";
import { parseTreeItemId } from "./utils/id";
import { GTreeItemCheckboxState } from "@grenton/design-system";

export type Props = {
    project: ProjectImpl;
    filter?: ProjectTreeFilterFunction<ProjectTreeItemData>;
    tagCategory: string;
    multiSelection: ObjectMultiSelection;
    onNodeClick: (node: ProjectTreeItem<ProjectTreeItemData>) => void;
    onNodeActionClick: (node: ProjectTreeItem<ProjectTreeItemData>, e: SyntheticEvent) => void;
    onItemSelectionToggle: (itemId:string, selected:boolean) => void;
};

// function findObjectsByNodeIds(ids: string[]):string[] {

//     const objects = ids
//         .map(parseTreeItemId)
//         .map(id=>id[1])
//         .filter(notEmpty)

//     return objects
// }


function findSelectedAndSelectables(
        items: ProjectTreeItem<ProjectTreeItemData>[], 
        multiSelection: ObjectMultiSelection
    ) : {selectable:Record<string,GTreeItemCheckboxState>,selected:string[]} {
    
    const selected:string[] = []
    let selectable:Record<string,GTreeItemCheckboxState> = {}

    items.forEach(item=>{
        if (item.data.type === ProjectTreeItemType.OBJECT) {
            const s = multiSelection.objects[item.data.objectId]
            if (s !== undefined) {
                selectable[item.id] = GTreeItemCheckboxState.ENABLED
                if (s) {
                    selected.push(item.id)
                }
            } else {
                selectable[item.id] = GTreeItemCheckboxState.DISABLED
            }
        }

        if (item.children) {
            const r = findSelectedAndSelectables(item.children, multiSelection)
            selectable = {...selectable, ...r.selectable}
            selected.push(...r.selected)
        }
    })

    return {selectable,selected}
}

export function MainObjectTree({ project, filter, tagCategory, multiSelection, onNodeClick: onNodeClickFromProps, onNodeActionClick, onItemSelectionToggle }: Props) {
    
    const [more, setMore] = useState<KeyMap>({})

    const onItemClick = (e:React.MouseEvent, item: ProjectTreeItem<ProjectTreeItemData>, data:any) => {
        if (data === 'action') {
            e.stopPropagation()
            return onNodeActionClick(item, e)
        }
        switch (item.data.type) {
            case ProjectTreeItemType.MORE:
                setMore({...more, [item.id]: true})
                break
            case ProjectTreeItemType.LESS:
                setMore({...more, [item.id]: false})
                break
            default:
                return onNodeClickFromProps(item)
        }
    }

    // it would be better to filter nodes 'on the fly', however filter is not filled by default so perf gain is not that big 
    const items = useMemo(()=> filterNodes(mainObjectTreeModel({
        multiSelectMode: multiSelection.mode,
        more,
        project,
        tagCategory
    }), filter || (() => (ProjectTreeFilterResult.INCLUDE_WITH_CHILDREN))), [more,project,tagCategory,filter,multiSelection])


    const itemsSelection: {selectable:Record<string,GTreeItemCheckboxState>,selected:string[]} = useMemo(()=>{
        return findSelectedAndSelectables(items, multiSelection)
    }, [items, multiSelection])


    return (
        <ErrorBoundary fallbackRender={(e)=><>{e.error}</>}>
        <ProjectComponentTree2
            itemRenderer={mainObjectTreeRenderer}
            items={items}
            selectionEnabled={multiSelection.mode}
            onItemClick={onItemClick}
            selectableItems={itemsSelection.selectable}
            selectedItems={itemsSelection.selected}
            onItemSelectionToggle={(itemId, selected) => {
                // due to way we encode itemIds, the last token is always the object id for OBJECT item
                const objectId = parseTreeItemId(itemId).pop()
                if (objectId) {
                    onItemSelectionToggle(objectId, selected)
                }
            }}
        />
        </ErrorBoundary>
    )
}
