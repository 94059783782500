import { Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { FieldErrorMessages, SideDialogTagSelector, WrapElements } from "../misc";
import { ProtocolTree } from "../protocolTree";
import { ObjectEditForm } from "@grenton/gm-logic";
import { ValidatorResult } from "@grenton/gm/editor/model-ui";
import { ObjectPropertiesFormMeta } from "../../types";
import { useMemo, useState } from "react";
import { GFieldset, GPseudoInput, TagLabel } from "@grenton/design-system";
import { AddCircleOutline } from "@mui/icons-material";
import { SideDialogMultiProtocolSelection } from "../misc/SideDialogMultiProtocolSelector";
import { Lists, notEmpty } from "@grenton/gm-common";
import { useTagContext } from "@grenton/gm/ui/tags";
import { useProject } from "@grenton/gm/ui";

type Props = {
    form: ObjectEditForm,
    errors: ValidatorResult,
    meta: ObjectPropertiesFormMeta
    onChange: (form: ObjectEditForm) => void
}

export function ObjectProperties({ form, errors, meta, onChange }: Props) {

    const [projectTags] = useProject(project => project.tags)
    const {createNewTag, createNewTagCategory} = useTagContext()
    const [openTagSelector, setOpenTagSelector] = useState(false)
    //const [functionalTypeSelector, setFunctionalTypeSelector] = useState(false)
    const [protocolSelector, setProtocolSelector] = useState(false)
    const closeProtocolSelector = ()=>setProtocolSelector(false)
    const closeTagSelector = ()=>setOpenTagSelector(false)
    //const closeFunctionalTypeSelector = ()=>setFunctionalTypeSelector(false)
    
    const selectFunctionalType = (type: string) => {
        applyChanges({ functionalType: type })
        //closeFunctionalTypeSelector()
    }

    const functionalTypes = useMemo(
        () => meta.findFunctionalTypes(form.extendingProtocols).map(t => ({ name: t, value: t })),
        [meta, form.extendingProtocols]
    )

    const allProtocols = useMemo(()=>[...meta.firmware.apis].map(p=>p.id).toSorted(), [meta])

    const extendingProtocols = useMemo(()=>
        form.extendingProtocols.map(ref=>meta.firmware.resolveApiRef({ref})).filter(notEmpty)
        , [form.extendingProtocols])

    const applyChanges = (changes: Partial<ObjectEditForm>) => {
        onChange({ ...form, ...changes })
    }

    function onToggleExtendedProtocol(protocol: string, selected: boolean): void {
        applyChanges({
            extendingProtocols: selected ? Lists.addIfNotPresent(form.extendingProtocols, protocol) : Lists.remove(form.extendingProtocols, protocol)
        })
    }

    return <Stack sx={{ flexDirection: 'row' }}>
        <Stack alignItems="start" gap={2} padding={2}>
            <TextField
                sx={{ width: 340 }}
                label="Id"
                disabled={true}
                value={form.id.join(', ')}
            />
            <TextField
                sx={{ width: 340 }}
                error={!errors.field('label')}
                helperText={<FieldErrorMessages errors={errors.field('label')} />}
                disabled={Boolean(form.disabled['label'])}
                value={form.name} 
                label="Name"
                onChange={(e) => applyChanges({ name: e.target.value })}
            />

            <FormControl disabled={Boolean(form.disabled['functionalType'])}>
                <InputLabel id="select-label">Functional Type</InputLabel>
                <Select label="Selectable Option" value={form.functionalType || ''} size="small" sx={{ minWidth: 200 }} onChange={(e)=>selectFunctionalType(e.target.value)}>
                {functionalTypes.map((type) => (
                    <MenuItem key={type.value} value={type.value}>{type.name}</MenuItem>
                ))}
                </Select>
            </FormControl>

            <GPseudoInput disabled={form.disabled['tags']} placeholder="Tags" onClick={_ => setOpenTagSelector(true)}>
                        {(() => {
                            const tags = form.tags || []
                            return tags.length ? <WrapElements>{tags.map(tag => {
                                return <Stack key={tag} direction="row" sx={{ alignItems: 'center' }}>
                                    <TagLabel
                                        label={tag}
                                        color={projectTags.getColor(tag.split(':')[0])}
                                    />
                                </Stack>
                            })}</WrapElements> : null
                        })()
                        }
            </GPseudoInput>

            </Stack>
            <Stack alignItems="start" gap={2} padding={2}>
                <TextField label="Protocol" sx={{ width: 340 }} disabled={true} value={meta.protocol.id}/>
                <GFieldset label="Extends" styles={{marginTop:'-6px', width:340}}>
                    <ProtocolTree data={extendingProtocols} />
                </GFieldset>
                {meta.protocol.editable && <Button onClick={()=>setProtocolSelector(true)} size="small" endIcon={<AddCircleOutline/>}>Extend</Button>}
            </Stack>

            <SideDialogTagSelector 
                open={openTagSelector} 
                onClose={closeTagSelector} 
                tags={projectTags.export()} 
                selected={form.tags} 
                onAdd={createNewTag}
                onCreateCategory={createNewTagCategory}
                onChange={tags => applyChanges({ tags })} />
            
            {/* <GSideDialog open={functionalTypeSelector} onClose={closeFunctionalTypeSelector}>
                <GDialogTitle onClose={closeFunctionalTypeSelector}>Select functional type</GDialogTitle>
                <DialogContent>
                    <MenuList variant="selectedMenu" autoFocusItem>
                        {functionalTypes.map((type) => (
                            <MenuItem key={type.value} value={type.value} onClick={_=>selectFunctionalType(type.value)}>{type.name}</MenuItem>
                        ))}
                    </MenuList>
                </DialogContent>
            </GSideDialog> */}

            <SideDialogMultiProtocolSelection 
                title="Select protocols to extend"
                open={protocolSelector}
                onClose={closeProtocolSelector}
                protocols={allProtocols}
                onToggle={(protocol,selected)=>onToggleExtendedProtocol(protocol,selected)}
                selected={form.extendingProtocols}/>
        
    </Stack>
}