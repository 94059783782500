import { Clear, Search } from "@mui/icons-material";
import { FormControl, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { GFieldset, GPseudoInput } from "../components";
import { GEnumSelector } from "../components/GEnumSelector";
import { LOREM_IPSUM_P } from "./common";

export function TextFieldsDemo() {

  const [search, setSearch] = useState("")


  const [pseudoInputValue, setPseudoInputValue] = useState<string|null>(null)

  return <Stack gap={2} alignItems={"start"}>

    <TextField label="Label" />

    <TextField label="Label" value={"abc123"} />  {/* onChange is required here, otherwise label is not shrunk */}
    <TextField label="Label" value={"abc123"} disabled={true} />
    <TextField label="Label" error={true} helperText="Incorrect entry." />
    <TextField variant="standard"
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      placeholder="Search"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{ fontSize: '1.4em' }}>
            <Search fontSize="inherit" color="inherit" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end" onClick={() => setSearch('')} sx={{ cursor: 'pointer' }}>
            <Clear />
          </InputAdornment>
        )
      }} />



    <FormControl sx={{width:200}}>
      <InputLabel>FormControl+Select</InputLabel>
      <Select label="FormControl+Select">
        <MenuItem value={10}>Ten</MenuItem>
        <MenuItem value={20}>Twenty</MenuItem>
        <MenuItem value={30}>Thirty</MenuItem>
      </Select>
    </FormControl>
    <GEnumSelector label="Enum selector" sx={{width:200}} value="a" options={[{ name: "A", value: "a" }, { name: "B", value: "b" }]} onChange={() => { }} />

    <GFieldset label="Custom fieldset">
      <Typography variant="m">{LOREM_IPSUM_P}</Typography>
    </GFieldset>

    <GPseudoInput onClick={() => {setPseudoInputValue(pseudoInputValue?null:'Any element(s)')}} placeholder="Pseudo input. Click me!">
      {pseudoInputValue}
    </GPseudoInput>


  </Stack>
}