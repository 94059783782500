import {FolderOutlined, AutoFixHighOutlined, EditNote, LanOutlined, SettingsOutlined} from "@mui/icons-material";
import {MenuItem} from "./types";

type MenuItemOrDivider = MenuItem | '-';

export const menuItems: (projectOpened:boolean)=>MenuItemOrDivider[] = (projectOpened)=> {
    return projectOpened ? [
        {id: 'editor', icon: <EditNote/>, label: 'Editor', tooltip: 'Editor'},
        {id: 'preparation', icon: <AutoFixHighOutlined/>, label: 'Overview', tooltip: 'Component Overview'},
        {id: 'hardware', icon: <LanOutlined/>, label: 'Hardware', tooltip: 'Hardware configuration'},
        {
            id: 'project',
            icon: <SettingsOutlined/>,
            label: 'Settings',
            tooltip: 'Project Settings'
        },
        // {id: 'sync', icon: <Sync/> , label: 'Send', tooltip: 'Send'},
    //    {id: '', icon:<Logout/>, label: 'Exit Project', tooltip: 'Exit Project'},
    // {id: 'app', icon: <AppShortcut/>, label: 'App', tooltip: 'App'},
        '-',
        
        {id: '', icon:<FolderOutlined/>, label: 'My Projects', tooltip: 'My Projects'}
    ] : [
        {id: '', icon:<FolderOutlined/>, label: 'My Projects', tooltip: 'My Projects'}
    ]
};
