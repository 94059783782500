import {useState} from "react";
import type { ReactElement } from "react";
import { Alert, Box, IconButton, Stack } from "@mui/material";
import { SectionContent } from '@grenton/design-system';
import { CategoryAccordion, ModalCategory, ModalTag } from "./components";
import type { ProjectTagsCategory } from "@grenton/gm-logic";
import { Add } from "@mui/icons-material";
import { useProject } from "@grenton/gm/ui";

export function ProjectTags(): ReactElement {

    const [categories] = useProject(project => project.tags.categories)
    const isCategories = Boolean(categories.length);
    const [isOpenAddCategory, setIsOpenAddCategory] = useState<boolean>(false);
    const [isOpenAddTag, setIsOpenAddTag] = useState<boolean>(false);
    const [holdCategory, setHoldCategory] = useState<ProjectTagsCategory | null>(null);

    const handleAddTag = ({ category }: {category: ProjectTagsCategory}): void => {
        setHoldCategory(category);
        setIsOpenAddTag(true);
    }

    return (
        <SectionContent>
            <Stack>
                <Box display={"flex"} justifyContent={"flex-end"} padding={1}>
                    <IconButton onClick={() => setIsOpenAddCategory(true)}><Add/></IconButton>
                    <ModalCategory isOpen={isOpenAddCategory} onClose={() => setIsOpenAddCategory(false)} />
                </Box>
                {!isCategories && <Alert severity="warning">You need to create a new category to add a tag.</Alert>}
                {holdCategory && <ModalTag isOpen={isOpenAddTag} onClose={() => setIsOpenAddTag(false)} category={holdCategory}/>}
                <div>
                {isCategories && categories.map((category) => (
                    <CategoryAccordion key={category.data.name} category={category} handleAddTag={handleAddTag} />
                ))}
                </div>
            </Stack>
        </SectionContent>
    );
}
