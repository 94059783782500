import { KeyMap } from "@grenton/gm-common";
import {objectNodes} from "./utils";
import { ProjectTreeItem, ProjectTreeItemComponentData, ProjectTreeItemData, ProjectTreeItemType } from "@grenton/gm/editor/components/projectComponentTree";
import { ProjectImpl, ProjectObjectImpl, sortTags } from "@grenton/gm-logic";
import { objectIconResolver } from "../../../../../shared/components/icons";
import { GTreeItemCheckboxState } from "@grenton/design-system";
import { encodeTreeItemId } from "./utils/id";

type Props = {
    more: KeyMap;
    project: ProjectImpl;
    tagCategory: string;
    multiSelectMode: boolean;
};

/*
* warning: duplicated nodes (with the same id) ARE PROHIBITED by RichViewTree although they work with SimpleViewTree!
* we need to construct id of each node with use of parent id and object id to avoid duplications.
* 
* the consequence is that we cannot easily map node id to object id and vice versa, because it is many to one relation.
*/
export function mainObjectTreeModel({more, project, tagCategory, multiSelectMode}: Props): ProjectTreeItem<ProjectTreeItemData>[] {

    const iconResolver = objectIconResolver(project.firmware)

    if (!tagCategory) {

        const byMod = project.topObjects
            .filter(o=>o.impl.type==='module')
            .reduce((acc, object) => {
            const id = object.impl.componentRef.componentId || ''
            return {...acc, [id]: (acc[id] || []).concat([object])}
        }, {} as { [mid: string]: ProjectObjectImpl[] })

        const modNodes = project.modules.filter(mod=>mod.type === 'module').map((mod) => {
            const sortKey = `${mod.ref} (${mod.uuid.substring(0, 8)})`
            const id = encodeTreeItemId(`mod:${mod.uuid}`)
            const item:ProjectTreeItem<ProjectTreeItemComponentData,ProjectTreeItemData>= {
                id,
                label: id,
                icon: null,
                sortKey,
                data: {
                    type: ProjectTreeItemType.MODULE,
                    module: {
                        id: mod.uuid,
                        ref: mod.ref,
                        type: mod.type,
                    }
                },
                children: objectNodes(multiSelectMode, id, more, byMod[mod.uuid] || [], iconResolver, project.objectResolver)
            }
            return item
        })
        const nonMods = objectNodes(multiSelectMode, encodeTreeItemId('mod:*'), more, 
            project.topObjects
            .filter(o=>o.impl.type!=='module')
            ,iconResolver, 
            project.objectResolver)

        return [...modNodes, ...nonMods]
    } else {
        const tags = [...project.tags.byCategoryName(tagCategory), ""].sort(sortTags);

        return tags.map((tag: string) => {
            const id = encodeTreeItemId(`tag:${tag}`);
            const filteredObjects = project.topObjects.filter(object => 
                (tag && object.tags.includes(tag)) ||
                (!tag && object.tags.empty) || 
                Object.values(object.init.outlets || {}).some(outlet =>
                        outlet.staticRefs && outlet.staticRefs.some(staticRef =>
                            project.objects[staticRef]?.tags.selected.includes(tag)
                        )
                )
            )

            const children = objectNodes(multiSelectMode, id, more, filteredObjects, iconResolver, project.objectResolver, tag)

            const tagValue = tag.split(':')[1] || `\u2217`
            return {
                id,
                label: tagValue,
                icon: null,
                sortKey: tagValue,
                data: {
                    type: ProjectTreeItemType.SPACE,
                    tag: tagValue
                },
                children,
                checkbox: GTreeItemCheckboxState.HIDDEN
            };
        });
    }
}

