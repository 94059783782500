import { KeyMap } from '@grenton/gm-common';
import { ObjectResolver, ProjectObjectImpl } from '@grenton/gm-logic';
import type { ProjectTreeItem, ProjectTreeItemData } from '../../../../projectComponentTree';
import { objectNode } from './objectNode';
import { GIconName } from '@grenton/gm/ui/icons';
import { sortTreeNodes } from '@grenton/gm/ui/sortTreeNodes';

export function objectNodes(
    multiSelectMode: boolean,
    parentId: string,
    more: KeyMap,
    objects: ProjectObjectImpl[],
    iconResolver: (object: ProjectObjectImpl) => GIconName | null,
    objectResolver: ObjectResolver,
    tag?: string,
): ProjectTreeItem<ProjectTreeItemData>[] {
    return objects.map((object) => objectNode(multiSelectMode, parentId, more, [object.uuid], object, iconResolver, objectResolver, tag)).sort(sortTreeNodes);
}
