import { GTreeItemCheckboxState } from '@grenton/design-system';
import { ProjectComponentInstance } from '@grenton/gm-common';
import { GIconName } from '@grenton/gm/ui/icons';

export enum ProjectTreeItemType {
    // tag
    SPACE = 1,

    OBJECT = 2,

    // primary tree: is actually an "event handler" node that
    // belongs to a controller or anonymous controller
    // target tree: unused
    EVENT = 3,

    // primary tree: rendered for controllers
    // target tree: rendered for all objects
    METHOD = 4,

    // primary tree: unused
    // target tree: rendered for all objects
    FEATURE = 5,

    //
    MODULE = 6,

    OUTLET = 8,
    MORE = 20,
    LESS = 21,
}

// TODO these generics are here only for mygrenton prototype?
export type ProjectTreeItem<T, CHILDREN = T> = {
    id: string;
    label: string;
    icon: GIconName | null;
    rootClassName?: string;
    highlight?: boolean;
    sortKey: string;
    children?: ProjectTreeItem<CHILDREN>[];
    disabled?: boolean;
    popup?: boolean;
    data: T;
    checkbox?: GTreeItemCheckboxState;
};

export type ProjectTreeItemData =
    | ProjectTreeItemOutletData
    | ProjectTreeItemTagData
    | ProjectTreeItemComponentData
    | ProjectTreeItemMethodData
    | ProjectTreeItemObjectData
    | ProjectTreeItemEventData
    | ProjectTreeItemFeatureData
    // | ProjectTreeItemContainerData
    | { type: ProjectTreeItemType.MORE }
    | { type: ProjectTreeItemType.LESS };

export type ProjectTreeItemOutletData = {
    type: ProjectTreeItemType.OUTLET;
    objectId: string;
    outletId: string;
    path: string[];
};

export type ProjectTreeItemTagData = {
    type: ProjectTreeItemType.SPACE;
    tag: string;
};

export type ProjectTreeItemComponentData = {
    type: ProjectTreeItemType.MODULE;
    module: ProjectComponentInstance;
    linking?: boolean;
    hw?: string; // mapped hardware
};

export type ProjectTreeItemObjectData = {
    type: ProjectTreeItemType.OBJECT;
    objectId: string;
    linking?: boolean;
    active?: boolean; // some nodes are containers only
    path: string[];

    // tag under which this item is displayed; used for selecting a default tag for adding new objects
    rootTag?: string;
    // is this object actually has root tag assigned or is it a child object w/o it (rendered 'greyed out' in the tree)
    assignedToRootTag?: boolean;
};

export type ProjectTreeItemMethodData = {
    type: ProjectTreeItemType.METHOD;
    objectId: string;
    methodId: string;
    scene: boolean;
    hasCode: boolean;
    path: string[];
};

export type ProjectTreeItemEventData = {
    type: ProjectTreeItemType.EVENT;
    objectId: string; // this may point to anonymous controller!
    sourceObjectId: string;
    eventId: string;
    hasCode: boolean;
    path: string[];
};

export type ProjectTreeItemFeatureData = {
    type: ProjectTreeItemType.FEATURE;
    objectId: string;
    featureId: string;
    readOnly: boolean;
    path: string[];
};

// export type ProjectTreeItemContainerData = {
//     type: ProjectTreeItemType.CONTAINER;
// };

export enum ProjectTreeFilterResult {
    INCLUDE,
    INCLUDE_WITH_CHILDREN,
    INCLUDE_NOT_EMPTY,
    EXCLUDE,
}

export type ProjectTreeFilterFunction<T> = (node: ProjectTreeItem<T>) => ProjectTreeFilterResult;
