import { Clear } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
    onClose?: () => void;
}>;

export function InlineEditPane({onClose, children}:Props) {
    return <Stack  alignItems="start" flexDirection={"row"} gap={2} padding={2} sx={{flexGrow:1}}>
        <Stack  alignItems="start" gap={2} padding={0} sx={{flexGrow:1}}>
            {children}
        </Stack>
        {onClose && <IconButton onClick={onClose}><Clear/></IconButton>}
    </Stack>
}