import { KeyMap, OUTLET_PARENT } from '@grenton/gm-common';
import { OutletImpl, ProjectObjectImpl } from '@grenton/gm-logic';
import { ProjectTreeItem, ProjectTreeItemData, ProjectTreeItemOutletData, ProjectTreeItemType } from '@grenton/gm/editor/components';
import { eventNodes } from './eventNodes';
import { sortTreeNodes } from '../../../../../../ui/sortTreeNodes';
import { concatTreeItemId } from './id';

export function outletNode(
    parentItemId: string,
    more: KeyMap,
    path: string[],
    object: ProjectObjectImpl,
    outlet: OutletImpl,
): ProjectTreeItem<ProjectTreeItemOutletData> {
    const id = concatTreeItemId(parentItemId, outlet.id);

    const _eventNodes = object.impl.type === 'script' ? eventNodes(id, more, path, object, object, outlet.api) : [];

    const _outletNodes = Object.values(outlet.api.outlets)
        .filter((o) => o.id !== OUTLET_PARENT)
        .map((o) => outletNode(id, more, [...path, o.id], object, o))
        .sort(sortTreeNodes);

    const item: ProjectTreeItem<ProjectTreeItemOutletData, ProjectTreeItemData> = {
        id,
        label: outlet.name,
        sortKey: outlet.name,
        icon: 'outlet',
        data: {
            type: ProjectTreeItemType.OUTLET,
            path,
            outletId: outlet.id,
            objectId: object.uuid,
        },
        // render "onEvent" nodes only for scriptable objects, for others are read only
        children: [..._eventNodes, ..._outletNodes],
    };
    return item;
}
