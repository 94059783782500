import { Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ProjectDocType } from '../project/store/project-repo';
import { format } from 'date-fns';
import { ArrowForward } from '@mui/icons-material';
import { useGrentonAuth } from '../auth';
import { useDispatcher } from '../ui';
import { LoadStoredProjectCommand } from '@grenton/gm-logic';
import { LoadSampleProjectCommand } from '@grenton/gm-logic';
import { useService } from '../providers';
import { useNavigate } from 'react-router-dom';
import { ROUTE_EDITOR } from '../layout';

function RecentProjectRow(props: { project: ProjectDocType; onLoad: () => void }) {
    return (
        <TableRow>
            <TableCell sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant="m" sx={{ fontWeight: 800 }}>
                    {props.project.name}
                </Typography>
                <Typography variant="xs">{props.project.id}</Typography>
            </TableCell>
            <TableCell sx={{ fontWeight: 400 }}>{props.project.createdBy.email}</TableCell>
            <TableCell sx={{ fontWeight: 400 }}>{format(Date.parse(props.project.updatedAt), 'yyyy-MM-dd HH:mm')}</TableCell>
            <TableCell align="right">
                <IconButton onClick={props.onLoad}>
                    <ArrowForward />
                </IconButton>
            </TableCell>
        </TableRow>
    );
}

export function RecentProjects() {
    const [recent, setRecent] = useState<ProjectDocType[]>([]);
    const dispatch = useDispatcher();
    const { user } = useGrentonAuth();
    const { projectRepo } = useService();
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            const sampleSmall: ProjectDocType = {
                id: 'sample-small',
                uid: user.sub,
                name: 'Sample Small Project',
                createdBy: { email: user.email, uid: user.sub },
                updatedAt: new Date().toISOString(),
            };

            const sampleLarge: ProjectDocType = {
                id: 'sample-large',
                uid: user.sub,
                name: 'Sample Large Project',
                createdBy: { email: user.email, uid: user.sub },
                updatedAt: new Date().toISOString(),
            };

            const subscription = projectRepo.observeAllByUser(user.sub).subscribe((projects) => {
                setRecent([sampleSmall, sampleLarge, ...projects]);
            });

            return () => {
                subscription.unsubscribe();
            };
        }
    }, [user]);

    const onLoad = (p: ProjectDocType) => {
        if (p.id === 'sample-small') {
            dispatch(new LoadSampleProjectCommand('small'));
        } else if (p.id === 'sample-large') {
            dispatch(new LoadSampleProjectCommand('large'));
        } else {
            dispatch(new LoadStoredProjectCommand(p.id));
        }
        navigate(ROUTE_EDITOR);
    };

    return (
        <Box sx={{ overflow: 'auto', padding: 3 }}>
            <Table size="small">
                <TableHead>
                    <TableRow sx={{ '& .MuiTableCell-root': { fontWeight: 400 } }}>
                        <TableCell>
                            <Typography variant="xs">Name</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="xs">Created By</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="xs">Updated At</Typography>
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {recent.map((p, i) => (
                        <RecentProjectRow key={i} project={p} onLoad={() => onLoad(p)} />
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
}
