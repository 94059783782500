import {ArrowForward, DeviceHub, Upload} from "@mui/icons-material";
import {
    Stack,
    Button,
    Card,
    CardHeader,
    CardContent,
    Typography,
    Box,
    ButtonBase,
    Dialog,
    DialogTitle,
    DialogContent,
    styled
} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import FolderIcon from '@mui/icons-material/Folder';
import {useNavigate} from "react-router";
import { RecentProjects } from "./RecentProjects";
import { useDispatcher } from "../ui";
import { LoadProjectFromJsonCommand, NewProjectCommand } from "@grenton/gm-logic";

function ProjectOption(props: { icon: React.ReactElement, title: string, desc: string, onClick: () => void }) {
    return <ButtonBase sx={{margin: 1, flexGrow: 1, flexShrink: 1, flexBasis: 0}} onClick={props.onClick}>
        <Card sx={{width: '100%', height: '100%', textAlign: 'start'}}>
            <CardHeader
                avatar={props.icon}
                action={<ArrowForward/>}
                title={props.title}
                subheader=""
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    {props.desc}
                </Typography>
            </CardContent>
        </Card></ButtonBase>
}

export default function () {

    const dispatcher = useDispatcher()
    // TODO move up
    const navigate = useNavigate()
    const [openLocalDialog, setOpenLocalDialog] = useState(false)

    const onNewProject = () => {
        dispatcher(new NewProjectCommand())
        navigate('/editor')
    }

    const onImportProject = () => {
        dispatcher(new NewProjectCommand())
        navigate('/hardware')
    }

    const onImportFromFile = () => {
        setOpenLocalDialog(true)
    }

    const onFileUploaded = (json: string) => {
        dispatcher(new LoadProjectFromJsonCommand(json))
        navigate('/editor')
    }

    const handleKeyPress = useCallback((event:KeyboardEvent) => {
        if (event.key.toUpperCase() === 'U' && event.ctrlKey) {
          navigate('/ui')
        }
      }, []);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
          document.removeEventListener('keydown', handleKeyPress);
        }
      }, [handleKeyPress])


    return <Box
        sx={{position: 'relative', height: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
        <Stack direction="column" sx={{display:"flex", overflow:"hidden", height:'80%'}}> 
        <Stack direction="row" justifyContent="center">
            <ProjectOption icon={<Upload color="primary"/>} title="Import from file"
                           desc="Import project stored on your local filesystem" onClick={onImportFromFile}/>
            <ProjectOption icon={<DeviceHub color="primary"/>} title="Import from CLU"
                           desc="Scan network for existing CLUs" onClick={onImportProject}/>
            <ProjectOption icon={<FolderIcon color="primary"/>} title="New Project"
                           desc="Start designing your project without existing hardware" onClick={onNewProject}/>
            {/* <ProjectOption icon={<FolderSpecial color="primary"/>} title="Recent Project"
                           desc="Open one of the recent projects" onClick={onRecentProject}/> */}
        </Stack>
        <RecentProjects/>
        </Stack>   

        <OpenLocalDialog
            open={openLocalDialog}
            onClose={() => setOpenLocalDialog(false)}
            onUpload={json => {
                onFileUploaded(json)
                setOpenLocalDialog(false)
            }
            }/>
    </Box>
}


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function OpenLocalDialog(props: { open: boolean, onClose: () => void, onUpload: (json: string) => void }) {
    return <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>Upload local configuration</DialogTitle>
        <DialogContent>

            <Button component="label" variant="contained" startIcon={<Upload/>}>
                Select file
                <VisuallyHiddenInput type="file" id="file-selector" onChange={(e) => {
                    const file = e.target?.files ? e.target.files[0] : undefined
                    if (!file) return
                    const reader = new FileReader()
                    reader.addEventListener('load', loadEvent => {
                        if (loadEvent.target?.result) {
                            props.onUpload(loadEvent.target.result as string)
                        }
                    });
                    reader.readAsText(file);

                }}/>
            </Button>
        </DialogContent>
    </Dialog>
}
