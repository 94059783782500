import { Box, DialogTitle } from "@mui/material"
import { spacing } from "../size"
import { GCloseDialogButton } from "./GCloseDialogButton"

type Props = {
    onClose? : ()=>void
    children?: React.ReactNode
}

export function GDialogTitle({children, onClose}:Props) {
    return <DialogTitle sx={{display:'flex',alignItems:'center'}}>
        <Box sx={{flexGrow:1}}>{children}</Box>
        {onClose &&  <GCloseDialogButton onClick={onClose} sx={{
            marginLeft: `${spacing.S}px`,
            marginRight: `-${spacing.S}px`
        }}/> }
    </DialogTitle>
}