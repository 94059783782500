import { GSideDialog } from "@grenton/design-system";
import { ObjectLibrary } from "./ObjectLibrary";
import { PropsWithChildren, useState } from "react";
import { useProjectOr } from "../hooks/useProject";
import { useDispatcher } from "../hooks/useDispatcher";
import { AddModuleCommand } from "@grenton/gm-logic";
import { AddVirtualObjectCommand } from "@grenton/gm-logic";
import { LibraryContext } from "./LibraryContext";


export function LibraryContextProvider({ children }:PropsWithChildren<{}>) {

    const [libOpen, setLibOpen] = useState<{open:boolean,selectedTag?:string}>({open:false});
    
    const closeLibrary = ()=>setLibOpen({open:false})

    const openLibrary = (selectedTag?:string)=>setLibOpen({open:true,selectedTag})
    const [libraryPage, setLibraryPage] = useState(0);
    const [firmware] = useProjectOr(project=>project.firmware, null)
    const dispatcher = useDispatcher()
    
    return <LibraryContext.Provider value={{ openLibrary }}>

    <GSideDialog
    align="start"
    width="75vw"
    open={libOpen.open}
    onClose={closeLibrary}
>
    {firmware && <ObjectLibrary
        onClose={closeLibrary}
        tab={libraryPage}
        setTab={setLibraryPage}
        firmware={firmware}
        onSelectComponent={component => {
            dispatcher(component.spec.type === 'module' ?
                new AddModuleCommand({ tag: libOpen.selectedTag, cmpId: component.id }) :
                new AddVirtualObjectCommand({ tag: libOpen.selectedTag, component }))
            closeLibrary()
        }}
        onSelectMisc={(type: string) => {
            switch (type) {
                case 'tag':
                    //TODO setAddingNewTag(true);
                    break
            }
            closeLibrary()
        }}
    /> }

    </GSideDialog>

    {children}
    </LibraryContext.Provider>
}