import { KeyMap } from '@grenton/gm-common';
import { eventNode } from './eventNode';
import { ProjectTreeItem, ProjectTreeItemData, ProjectTreeItemType } from '@grenton/gm/editor/components/projectComponentTree';
import { ProjectObjectImpl, ProjectObjectApi } from '@grenton/gm-logic';
import { sortTreeNodes } from '../../../../../../ui/sortTreeNodes';

export function eventNodes(
    parentId: string,
    more: KeyMap,
    path: string[],
    // logical owner of event handlers
    handlerObject: ProjectObjectImpl,
    // source of events, it can be a hardware object
    sourceObject: ProjectObjectImpl,
    // which API defines this event (it can be API of sourceObject or API of an outlet)
    api: ProjectObjectApi,
): ProjectTreeItem<ProjectTreeItemData>[] {
    const moreId = parentId + `.*`;

    const events = Object.values(api.events)
        .map((event) => ({
            id: event.id,
            name: event.name,
            primary: event.spec.primary === true,
            hasCode: handlerObject.scripts.scripts[path.slice(1).join('.') + '.' + event.id]?.hasContent === true,
        }))
        .filter((e) => more[moreId] || e.primary || e.hasCode);

    const nodes: ProjectTreeItem<ProjectTreeItemData>[] = events
        .map((event) => eventNode(parentId, handlerObject, sourceObject, [...path, event.id], event))
        .sort(sortTreeNodes);

    // has more?
    if (nodes.length < Object.values(api.events).length) {
        nodes.push({
            id: moreId,
            label: 'more',
            icon: null,
            sortKey: '',
            data: {
                type: ProjectTreeItemType.MORE,
            },
            children: [],
        });
    } else {
        nodes.push({
            id: moreId,
            label: 'less',
            icon: null,
            sortKey: '',
            data: {
                type: ProjectTreeItemType.LESS,
            },
            children: [],
        });
    }

    return nodes;
}
