import { OutletImpl, ProjectObjectImpl } from '@grenton/gm-logic';
import { ProjectTreeItemOutletData, ProjectTreeItem, ProjectTreeItemType } from '@grenton/gm/editor/components';

export function outletNode(parentId: string, path: string[], object: ProjectObjectImpl, outlet: OutletImpl): ProjectTreeItem<ProjectTreeItemOutletData> {
    return {
        id: `${parentId}.${outlet.id}`,
        label: outlet.name,
        sortKey: outlet.name,
        icon: 'outlet',
        data: {
            type: ProjectTreeItemType.OUTLET,
            path,
            outletId: outlet.id,
            objectId: object.uuid,
        },
        children: [],
    };
}
