import {useState} from "react";
import type {ReactElement} from "react";
import {useForm} from "react-hook-form";
import {SectionContent} from '@grenton/design-system';
import {InitialData} from './types';
import GMTextField from "../../../ui/controls/GMTextField";
import {useTheme, Stack, IconButton, TableBody, Table, TableHead, TableRow, TableCell, MenuItem, Button} from "@mui/material";
import {List as ListIcon} from "@mui/icons-material";
import {ModalFullScreenMobile} from "@grenton/design-system";
import {useObservable} from "@grenton/utils";
import {EditProjectSettingsCommand,DeleteProjectCommand} from '../../../project';
import { useDispatcher, useProject } from "@grenton/gm/ui";
import { useService } from "@grenton/gm/providers";
import { useNavigate } from "react-router-dom";
import { ROUTE_ROOT } from "@grenton/gm/layout";

export function ProjectGeneralSetting(): ReactElement {
    const theme = useTheme();
    const [showAllRevisions, setShowAllRevisions] = useState(false)
    const [project] = useProject()
    const {repoClient} = useService()
    const [firmwares] = useObservable(repoClient.firmwares)
    const dispatch = useDispatcher()
    const navigate = useNavigate()

    const {handleSubmit, register, formState: {errors}, getValues} = useForm<InitialData>({
        defaultValues: {
            label: project.label,
            firmware: project.firmware.version
        }
    })

    const onSubmit = (updateArgs: InitialData) => {
        dispatch(new EditProjectSettingsCommand(updateArgs))
    }

    const onDelete = () => {
        dispatch(new DeleteProjectCommand(project.uuid))
        navigate(ROUTE_ROOT);
    }

    return (
        <SectionContent>

            <form
                onSubmit={handleSubmit(onSubmit)}
                style={{
                    margin: "0 auto",
                    maxWidth: "80%",
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: "center",
                    gap: theme.spacing(3)
                }}
            >
                <GMTextField
                    size="small"
                    label="Firmware"
                    select
                    variant="outlined"
                    defaultValue={getValues('firmware')}
                    inputProps={register('firmware', {
                        required: 'Firmware is required',
                    })}
                    error={!!errors.firmware}
                    helperText={errors.firmware?.message}
                    onBlur={handleSubmit(onSubmit)}
                >
                    {firmwares?.map(f => <MenuItem key={f.version} value={f.version}>{f.version}</MenuItem>)}
                </GMTextField>
                <GMTextField
                    size="small"
                    label="Name"
                    variant="outlined"
                    {...register('label', {required: "Label is required"})}
                    error={!!errors.label}
                    helperText={errors.label?.message}
                    onBlur={handleSubmit(onSubmit)}
                />
                <GMTextField
                    size="small"
                    value={project.uuid || ""}
                    label="project ID"
                    variant="outlined"
                    disabled
                />
                <Stack alignItems={"end"} position={"relative"} width={"100%"} flexDirection={"row"}>
                    <GMTextField

                        size="small"
                        value={project.revisions.head.tag || ""}
                        label="version"
                        variant="outlined"
                        disabled
                    />
                    <IconButton sx={{position: 'absolute', right: 0}}
                                onClick={() => setShowAllRevisions(true)}><ListIcon/></IconButton>
                </Stack>

                <Button onClick={onDelete}>Delete project</Button>
            </form>

            

            <ModalFullScreenMobile isOpen={showAllRevisions} onClose={() => setShowAllRevisions(false)}
                                   title={"All revisions"}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Tag</TableCell>
                            <TableCell>Author</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell sx={{maxWidth: 200}}>Note</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {project.revisions.all.map(rev => {
                            return <TableRow key={rev.tag}>
                                <TableCell>{rev.tag}</TableCell>
                                <TableCell>{rev.author}</TableCell>
                                <TableCell>{rev.ts.replace('T', ' ')}</TableCell>
                                <TableCell>{rev.note}</TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </ModalFullScreenMobile>
        </SectionContent>
    );
}
