import { schema } from "@grenton/gm-common"
import { Checkbox, FormControlLabel, TextField } from "@mui/material"
import { EnumSelector } from "../enumSelector"

type Props = {
    label: string
    type : schema.PropertyValueType
    value?: schema.PropertyValue,
    oneOf?: Array<{ const: schema.PropertyValue; title: string }>
    onClick?: (e: React.MouseEvent) => void
    onChange: (value: schema.PropertyValue) => void
    required?: boolean
    minWidth?: number
}

export function PropertyValueField({onClick,label,onChange, type,value, oneOf, required,minWidth}:Props) {

    if (oneOf) {
        return <EnumSelector
            minWidth={minWidth}
            required={required}
            label={label}
            value={value}
            options={oneOf.map(o => ({name: o.title, value: o.const})) || []}
            onChange={onChange}
        />
    }

    switch (type) {
        case 'null':
            return null
        case 'boolean':
            return <FormControlLabel
            sx={{minWidth}}
            labelPlacement="end"
            label={label}
            control={
                <Checkbox required={required} onClick={onClick} checked={Boolean(value)} onChange={e=>onChange(e.target.checked)} />
            }/>
        default: 
            return <TextField 
                sx={{minWidth}}
                required={required}
                inputMode={type === 'integer' || type === 'number' ? 'numeric' : 'text'}
                onClick={onClick} 
                value={value||''}
                onChange={e=>onChange(e.target.value)}
                label={label} 
                size="small" 
                variant="outlined"
            />
    }


}