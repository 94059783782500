import { ProjectTreeItem, ProjectTreeItemEventData, ProjectTreeItemType } from '@grenton/gm/editor/components/projectComponentTree';
import { ProjectObjectImpl } from '@grenton/gm-logic';
import { concatTreeItemId } from './id';

export function eventNode(
    parentItemId: string,
    handlerObject: ProjectObjectImpl,
    sourceObject: ProjectObjectImpl,
    path: string[],
    event: { id: string; name: string; primary: boolean; hasCode: boolean },
): ProjectTreeItem<ProjectTreeItemEventData> {
    const id = concatTreeItemId(parentItemId, event.id);
    return {
        id,
        label: event.name,
        icon: 'event',
        sortKey: event.name,
        data: {
            type: ProjectTreeItemType.EVENT as const,
            path,
            objectId: handlerObject.uuid,
            sourceObjectId: sourceObject.uuid,
            eventId: event.id,
            hasCode: event.hasCode,
        },
        children: [],
    };
}
