import {theme2} from "@grenton/design-system";
import type {PropsWithChildren, ReactElement} from "react";
import {BrowserRouter} from "react-router-dom";
import {CssBaseline, ThemeProvider} from '@mui/material';
import { ServicesProvider } from "./ServiceProvider";
import { GCustomStyles } from "@grenton/design-system/src/theme2/GCustomStyles";
import { TagContextProvider } from "../ui/tags/TagContextProvider";
import { LibraryContextProvider } from "../ui/library";
import { NotificationContextProvider } from "../notifications";
import { NetworkContextProvider } from "../hardware/NetworkContext";


type Props = PropsWithChildren<{}>

export function ProviderWrapper({children}: Props): ReactElement {
    return (
        <ServicesProvider>
        <BrowserRouter>
            <ThemeProvider theme={theme2}>
                <CssBaseline/>
                <GCustomStyles/>
                {/* providers of UI components must be inside theme provider */}
                <NetworkContextProvider>
                    <TagContextProvider>
                        <LibraryContextProvider>
                            <NotificationContextProvider>
                                {children}
                            </NotificationContextProvider>
                        </LibraryContextProvider>
                    </TagContextProvider>
                </NetworkContextProvider>
            </ThemeProvider>
        </BrowserRouter>
        </ServicesProvider>
    )
}
