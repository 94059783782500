import {useCallback, useEffect, useMemo} from 'react';
import type {ReactElement} from 'react';
import {Stack, FormControlLabel, Checkbox, MenuItem} from '@mui/material';
import {useForm} from 'react-hook-form';
import type {EditUserProps} from './types';
import GMTextField from '../../../../../ui/controls/GMTextField';
import {ModalFullScreenMobile} from '@grenton/design-system';
import {ProjectUserImpl, TEMPORARY_DEFAULT_USER_ROLES} from '@grenton/gm-logic';
import {FORM_FIELD} from '../../consts';
import {AccessLevels} from "@grenton/gm-common";
import { InputUser } from '../../../../../security/inputUser';

export function EditUser({isOpen, onClose, onSubmitEditUser, user}: EditUserProps): ReactElement {
    const formMethods = useForm<InputUser & { changePassword: boolean }>({
        defaultValues: {
            name: user.name,
            changePassword: false,
            pwd: '',
            pwdRepeat: '',
            accessLevel: user.accessLevel,
            disabled: user.disabled,
        }
    });


    useEffect(() => {
        formMethods.setValue('name', user.name);
        formMethods.setValue('accessLevel', user.accessLevel);
        formMethods.setValue('disabled', user.disabled);
    }, [user]);

    const {register, watch, handleSubmit, reset, formState: {errors}} = formMethods;
    const changePassword = watch('changePassword');

    const menuItems = useMemo(() => AccessLevels.map(level => (
        <MenuItem key={level} value={level}>{level}</MenuItem>
    )), []);

    const onSubmit = useCallback((form: InputUser & { changePassword: boolean }) => {
        const updatedUser = new ProjectUserImpl({
            name: user.name,
            pwd: form.changePassword ? form.pwd : user.pwd,
            roles: TEMPORARY_DEFAULT_USER_ROLES,
            accessLevel: form.accessLevel,
            disabled: form.disabled
        });
        onSubmitEditUser({user: updatedUser, changePassword: form.changePassword});
    }, [user, onSubmitEditUser]);

    const handleCancel = useCallback(() => {
        onClose();
        reset();
    }, [onClose, reset]);

    return (
        <ModalFullScreenMobile isOpen={isOpen} onClose={handleCancel} onSave={handleSubmit(onSubmit)}
                               title={`Edit ${user.name}`}>
            <form onSubmit={handleSubmit(onSubmit)} style={{minWidth: '25rem'}}>
                <Stack spacing={2}>
                    <GMTextField
                        size='small'
                        label='Name'
                        {...register('name')}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        disabled
                    />
                    <FormControlLabel
                        control={<Checkbox {...register('changePassword')} />}
                        label='Change password'
                    />
                    {changePassword && (
                        <>
                            <GMTextField
                                size='small'
                                label='Password'
                                {...register('pwd', FORM_FIELD.password)}
                                error={!!errors.pwd}
                                helperText={errors.pwd?.message}
                            />
                            <GMTextField
                                size='small'
                                label='Password (repeat)'
                                {...register('pwdRepeat', {
                                    validate: (pwdRepeat) => pwdRepeat === watch('pwd') || 'Passwords must match'
                                })}
                                error={!!errors.pwdRepeat}
                                helperText={errors.pwdRepeat?.message}
                            />
                        </>
                    )}
                    <GMTextField
                        select
                        size="small"
                        label="Access Level"
                        error={!!errors.accessLevel}
                        defaultValue={user?.accessLevel}
                        helperText={errors.accessLevel?.message}
                        {...register('accessLevel', FORM_FIELD.accessLevel)}
                        onChange={(e) => formMethods.setValue('accessLevel', e.target.value)}
                    >
                        {menuItems}
                    </GMTextField>
                    <FormControlLabel
                        control={<Checkbox {...register('disabled')} />}
                        label='Disabled'
                    />
                </Stack>
            </form>
        </ModalFullScreenMobile>
    );
}
