import { useState, useMemo } from "react";
import {
    Chip,
    DialogContent,
    FormControl,
    InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import { Lists, Maps, ObjectSelector } from "@grenton/gm-common";
import { FieldErrorMessages, SideDialogTagSelector, WrapElements } from "../misc";
import { OutletTypeSelector, OutletObjectSelector } from "./components";
import { GFieldset, GPseudoInput, TagLabel } from "@grenton/design-system";
import { ProjectObjectImpl } from "@grenton/gm-logic";
import { parseIntOrUndefined } from './utils';
import type { ObjectOutletFormMeta } from './types';
import { fontSizedIcon } from "@grenton/gm/ui/icons";
import { objectIconResolver } from "@grenton/gm/shared";
import { GSideDialog } from "@grenton/design-system/src/theme2/components/GSideDialog";
import { GDialogTitle } from "@grenton/design-system/src/theme2/components/GDialogTitle";
import { ObjectOutletForm } from "@grenton/gm-logic";
import { InlineEditPane } from "../misc/InlineEditPane";
import { ValidatorResult } from "@grenton/gm/editor/model-ui";
import { SideDialogProtocolSelection } from "../misc/SideDialogProtocolSelector";


type Props = {
    meta: ObjectOutletFormMeta;
    form: ObjectOutletForm;
    errors: ValidatorResult;
    onChange: (form: ObjectOutletForm) => void;
    onClose: () => void;
};

export function OutletEdit({ meta, form, onChange: onEdit, onClose, errors }: Props) {

    const [objectApiSelectorOpen, setObjectApiSelectorOpen] = useState(false)

    const applyChanges = (changes: Partial<ObjectOutletForm>) => {
        const updated = { ...form, ...changes }
        onEdit(updated)
    }

    const selectedObjects = useMemo(() => meta.objectResolver(form), [form])

    const allTags = useMemo(() => {
        let all = meta.project.tags
        all = all.withCategory('relative', '#aaa', true)
        all = all.withTag("relative:controller's tag")

        all.categories.forEach(c => {
            c.values.all.forEach(tag => {
                all = all.withTag(c.name + ":" + tag + ".*")
            })
        })

        return all
    }, [form]);

    const allowedTypes = useMemo(() => form.apiRef ? meta.project.firmware.resolveAllowedTypes(form.apiRef) : [], [form.apiRef])
    const allowedApis = useMemo(() => form.apiRef ? meta.project.firmware.resolveAllowedApis(form.apiRef) : [], [form.apiRef])


    const onDeleteSelector = (sel: 'id' | 'tag' | 'type', value: string) => {
        const selector = form.config.selector
        let updated: ObjectSelector = {}
        switch (sel) {
            case 'id':
                updated = { ...selector, ids: Lists.remove(selector.ids, value) }
                break;
            case 'tag':
                updated = { ...selector, tags: Lists.remove(selector.tags, value) }
                break;
            case 'type':
                updated = { ...selector, types: Lists.remove(selector.types, value) }
                break;
        }
        applyChanges({ config: { ...form.config, selector: updated } })
    }

    // TODO silly, refactor
    const tagWrap = (tags?: string[]) => {
        return (tags||[]).map(t=>{
            switch (t) {
                case '$ctrl': return "relative:controller's tag"
                case '$ctrl.*': return "relative:controller's tag.*"
                default:
                    return t
            }
        })
    }

    const tagUnwrap = (tags?: string[]) => {
        return tags?.map(t => t === "relative:controller's tag" ? "$ctrl" : t === "relative:controller's tag.*" ? "$ctrl.*" : t)
    }

    // edit selectors
    const [outletSelectorType, setOutletSelectorType] = useState<'tags' | 'types' | 'ids' | null>(null)

    const closeOutletSelector = () => {
        setOutletSelectorType(null)
    }

    const iconResolver = objectIconResolver(meta.project.firmware)

    return (
        <InlineEditPane onClose={onClose}>
            <Stack sx={{ flexDirection: 'row', flexGrow: 1, gap:2, width:'100%' }}>
                <Stack sx={{ flexDirection: 'column', flexGrow: 2, gap: 2 }}>

                    <TextField
                        error={!errors.field('label')}
                        disabled={!form.editable}
                        helperText={<FieldErrorMessages errors={errors.field('label')} />}
                        value={form.spec.label}
                        label="Name"
                        variant="outlined"
                        onChange={(e) => applyChanges({ spec: { ...form.spec, label: e.target.value } })} />

                    <TextField
                        fullWidth
                        error={!errors.field('description')}
                        helperText={<FieldErrorMessages errors={errors.field('description')} />}
                        value={form.spec.description || ""}
                        label="Description" 
                        variant="outlined"
                        onChange={(e) => applyChanges({ spec: { ...form.spec, description: e.target.value } })} />

                    <Stack sx={{flexDirection:'row', gap:1, justifyContent:'space-between'}}>
                    <FormControl fullWidth={false} sx={{ width: 200 }}>
                        <InputLabel>Protocol</InputLabel>
                        <Select label="Protocol" open={false} disabled={!form.editable} value={form.apiRef||''} size="small" sx={{ minWidth: 200 }} onOpen={(e) => {
                            e.stopPropagation();
                            setObjectApiSelectorOpen(true);
                        }}>
                            {meta.types.map((type) => (<MenuItem key={type} value={type}>{type}</MenuItem>))}
                        </Select>
                    </FormControl>

                    <TextField
                        sx={{ width: 100 }}
                        error={!errors.field('maxItems')}
                        disabled={!form.editable}
                        type="number"
                        helperText={<FieldErrorMessages errors={errors.field('maxItems')} />}
                        size="small" value={form.spec.maxItems} label="Max items" variant="outlined"
                        onChange={(e) => applyChanges({ spec: { ...form.spec, maxItems: parseIntOrUndefined(e.target.value) } })} />
                    </Stack>

                    <Typography variant="m">Selection filter</Typography>

                    <GPseudoInput disabled={!form.config.editable} placeholder="Tags" onClick={_ => setOutletSelectorType('tags')}>
                        {(() => {
                            const tags = form.config.selector.tags || []
                            return tags.length ? <WrapElements>{tags.map(tag => {
                                return <Stack key={tag} direction="row" sx={{ alignItems: 'center' }}>
                                    <TagLabel
                                        label={tag}
                                        color={meta.project.tags.getColor(tag.split(':')[0])}
                                    />

                                </Stack>
                            })}</WrapElements> : null
                        })()
                        }
                    </GPseudoInput>

                    <GPseudoInput disabled={!form.config.editable} placeholder="Functional type" onClick={_ => setOutletSelectorType('types')}>
                        {
                            (() => {
                                const types = form.config.selector.types || []
                                return types.length ? <WrapElements>{types.map(type => (<Chip key={type} sx={{
                                    margin: '1px',
                                    background: '#aaa',
                                    color: '#fff',
                                    borderRadius: 1
                                }} size="small" label={`${type}`}
                                    onDelete={() => onDeleteSelector('type', type)} />))}</WrapElements>
                                    : null
                            })()
                        }
                    </GPseudoInput>

                    <GPseudoInput disabled={!form.config.editable} placeholder="Objects" onClick={_ => setOutletSelectorType('ids')}>
                        {
                            (() => {
                                const objects = (form.config.selector.ids || [])
                                    .map(id => meta.project.getObjectById(id)).filter((obj): obj is ProjectObjectImpl => !!obj)
                                return objects.length ? <WrapElements>{objects.map(obj => (<Chip key={obj.uuid} sx={{
                                    margin: '1px',
                                    background: '#aaa',
                                    color: '#fff',
                                    borderRadius: 1
                                }} size="small" label={obj.label}
                                    onDelete={() => onDeleteSelector('id', obj.uuid)} />))}</WrapElements>
                                    : null
                            })()
                        }
                    </GPseudoInput>
                </Stack>
                <GFieldset styles={{ minWidth: 200, overflow: 'hidden' }} label="Selection preview">
                    <List dense={true} disablePadding={true}
                        sx={{ overflow: 'auto', height: '100%', width: '100%', padding: 0 }}>
                        {selectedObjects.map((obj, i) => (
                            <ListItem key={obj.uuid} alignItems="center" sx={{ opacity: i < (form.spec.maxItems || 10000) ? 1 : 0.3 }}>
                                <ListItemIcon sx={{ margin: 0, padding: 0, minWidth: 20 }}>
                                    {fontSizedIcon(iconResolver(obj))}
                                </ListItemIcon>
                                <ListItemText
                                    primary={obj.label}
                                />
                            </ListItem>
                        ))}
                    </List>
                </GFieldset>
            </Stack>

            <SideDialogProtocolSelection 
                title="Select outlet protocol"
                selected={form.apiRef}
                protocols={meta.types}
                open={objectApiSelectorOpen} 
                onClose={() => setObjectApiSelectorOpen(false)} 
                onSelect={(protocol) => {applyChanges({ apiRef: protocol }); setObjectApiSelectorOpen(false)}}/>

            <SideDialogTagSelector 
                open={outletSelectorType === 'tags'} 
                onClose={closeOutletSelector} 
                tags={allTags.export()}
                selected={tagWrap(form.config.selector.tags)}
                onChange={tags => applyChanges({
                    config: {
                        ...form.config,
                        selector: {
                            ...form.config.selector,
                            tags: tagUnwrap(tags)
                        }
                    }
                })}
                />

            <GSideDialog open={outletSelectorType === 'types'} onClose={closeOutletSelector}>
                <GDialogTitle onClose={closeOutletSelector}>Functional types</GDialogTitle>
                <DialogContent>
                    <OutletTypeSelector allowedTypes={allowedTypes}
                        allowedApis={allowedApis}
                        // these selectors should accept/return arrays !
                        selected={Maps.asKeyMap(form.config.selector?.types)}
                        onChange={types => applyChanges({
                            config: {
                                ...form.config,
                                selector: {
                                    ...form.config.selector,
                                    types: Maps.reduceKeyMap(types)
                                }
                            }
                        })} />
                </DialogContent>
            </GSideDialog>

            <GSideDialog open={outletSelectorType === 'ids'} onClose={closeOutletSelector}>
                <GDialogTitle onClose={closeOutletSelector}>Objects</GDialogTitle>
                <DialogContent>
                    <OutletObjectSelector
                        allowedApis={allowedApis}
                        project={meta.project}
                        ids={Maps.asKeyMap(form.config.selector?.ids)}
                        onChange={(_) => {
                            applyChanges({
                                config: {
                                    ...form.config,
                                    selector: {
                                        ...form.config.selector,
                                        ids: form.config.selector?.ids // TODO toggle [id]
                                    }
                                }
                            })
                        }}
                    />
                </DialogContent>
            </GSideDialog>
        </InlineEditPane>
    )
}
