import { Button } from "@mui/material";
import { PropertyValueField } from "../propertyValueField";
import { schema } from "@grenton/gm-common";
import { useState } from "react";
import { InlineEditPane } from "../misc/InlineEditPane";

type Props = {
    spec:schema.Method
    onSubmit: (params: Record<string,schema.PropertyValue>) => void
    onClose: () => void
}

export function MethodParamsPane({spec,onSubmit,onClose}: Props) {

    const [values,setValues] = useState<Record<string,schema.PropertyValue>>({})

    return <InlineEditPane onClose={onClose}>
        {spec.params?.map((param) => 
        <PropertyValueField 
            key={param.id} 
            type={param.type} 
            value={values[param.id]} 
            label={param.label||param.id} 
            onChange={(v) => setValues({...values,[param.id]:v})} />)}
        
        <Button variant="contained" onClick={()=>onSubmit(values)}>Call</Button>
    </InlineEditPane>
}