import { EditableList } from "../editableList";
import { ObjectEventForm } from "@grenton/gm-logic";
import { ApiItemListProps, ApiListItem, ApiListItemRenderer } from "../common";

export function EventList({ items, editedItemId, onAdd, onEdit, onDelete, children }: ApiItemListProps<ObjectEventForm>) {

    // can be moved up
    const onEditItem = (id: string) => {
        onEdit && onEdit(id)
    }

    const listItems : ApiListItem<ObjectEventForm>[] = Object.entries(items).map(([id,form]) => ({ 
        id, 
        selectable: form.editable, 
        removeable: form.editable, 
        form
    }))

    return <EditableList
        shrunkWidth={250}
        selectedItem={editedItemId}
        onSelectItem={onEditItem}
        onDeleteItem={onDelete}
        onNewItem={onAdd}
        items={listItems}
        renderer={ApiListItemRenderer}>
        {children}
    </EditableList>

}