import { ProjectTreeItem, ProjectTreeItemData, ProjectTreeItemObjectData, ProjectTreeItemType } from '@grenton/gm/editor/components/projectComponentTree';
import { ProjectObjectImpl } from '@grenton/gm-logic';
import { GIconName } from '@grenton/gm/ui/icons';

export function objectNodes(
    parentId: string,
    objects: ProjectObjectImpl[],
    filter: (obj: ProjectObjectImpl) => boolean,
    iconResolver: (obj: ProjectObjectImpl) => GIconName | null,
): ProjectTreeItem<ProjectTreeItemData>[] {
    const objectNode = (object: ProjectObjectImpl): ProjectTreeItem<ProjectTreeItemObjectData> => {
        const active = filter(object);
        const id = `${parentId}:${object.uuid}`;
        return {
            id,
            label: object.label,
            icon: iconResolver(object) || 'unknown',
            sortKey: object.label,
            data: {
                active,
                path: [object.uuid],
                type: ProjectTreeItemType.OBJECT,
                objectId: object.uuid,
            },
            children: [], //TODOX Maps.values(object.children).filter(filter).map(objectNode)
        };
    };
    // we want to show objects with compatible api or parents that have compabile children
    return objects.map(objectNode).filter((node) => node.data.active || node.children?.length);
}
