import { notEmpty } from '@grenton/utils';
import { isParentOrObject } from '@grenton/gm-common';
import { ObjectResolver, ProjectObjectImpl } from '@grenton/gm-logic';
import { outletNode } from './outletNode';
import {
    ProjectTreeItemFeatureData,
    ProjectTreeItemMethodData,
    ProjectTreeItemObjectData,
    ProjectTreeItemData,
    ProjectTreeItem,
    ProjectTreeItemType,
    sortTreeNodes,
} from '@grenton/gm/editor/components';
import { GIconName } from '@grenton/gm/ui/icons';
import { createIndexedOutletId } from '@grenton/gm/editor/components/mainObjectTreePane/components/mainObjectTree/utils/objectNode';

export function objectNode(
    parentId: string,
    path: string[],
    object: ProjectObjectImpl,
    actionsMode: boolean,
    iconResolver: (object: ProjectObjectImpl) => GIconName | null,
    objectResolver: ObjectResolver,
    rootTag?: string,
): ProjectTreeItem<ProjectTreeItemObjectData> {
    const id = `${parentId}.${object.uuid}`;

    const _featureNodes: ProjectTreeItem<ProjectTreeItemFeatureData>[] = actionsMode
        ? []
        : Object.values(object.api.features)
              .map((feature) => ({
                  id: `${id}.${feature.id}`,
                  label: feature.name,
                  icon: 'feature' as GIconName,
                  sortKey: feature.name,
                  data: {
                      type: ProjectTreeItemType.FEATURE as const,
                      path: [...path, feature.id],
                      objectId: object.uuid,
                      featureId: feature.id,
                      readOnly: !!feature.spec.readOnly,
                  },
              }))
              .sort(sortTreeNodes);

    const _methodNodes: ProjectTreeItem<ProjectTreeItemMethodData>[] = actionsMode
        ? []
        : Object.values(object.api.methods)
              .filter((method) => !method.spec.private)
              .map((method) => ({
                  id: `${id}.${method.id}`,
                  icon: method.spec.scene ? ('scene' as GIconName) : ('method' as GIconName),
                  label: method.name,
                  sortKey: method.name,
                  data: {
                      type: ProjectTreeItemType.METHOD as const,
                      path: [...path, method.id],
                      objectId: object.uuid,
                      methodId: method.id,
                      scene: !!method.spec.scene,
                      hasCode: false, //irrelevant
                  },
              }))
              .sort(sortTreeNodes);

    const _outletNodes: ProjectTreeItem<ProjectTreeItemData>[] = Object.values(object.api.outlets)
        .filter((outlet) => !isParentOrObject(outlet.id)) // TODO allow parent outlet? what about cyclic references
        .map((outlet) => {
            const outletConfig = object.init.outlets[outlet.id];
            if (outletConfig?.isStatic) {
                // static child objects
                return outletConfig.staticRefs
                    .map(objectResolver)
                    .filter(notEmpty)
                    .map((child, index) =>
                        objectNode(
                            id,
                            [...path, createIndexedOutletId(outlet, outletConfig, index)],
                            child,
                            actionsMode,
                            iconResolver,
                            objectResolver,
                            rootTag,
                        ),
                    );
            } else {
                // regular outlet
                return [outletNode(id, [...path, outlet.id], object, outlet)];
            }
        })
        .flat()
        .sort(sortTreeNodes);

    return {
        id,
        label: object.label,
        sortKey: object.label,
        rootClassName: 'object-node',
        data: {
            type: ProjectTreeItemType.OBJECT as const,
            path,
            objectId: object.uuid,
            assignedToRootTag: !rootTag || object.hasTag(rootTag),
        },
        children: [..._methodNodes, ..._featureNodes, ..._outletNodes],
    } as ProjectTreeItem<ProjectTreeItemObjectData, ProjectTreeItemData>;
}
