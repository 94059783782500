import { PropsWithChildren, useState } from "react";
import { AddTagDialog } from "./AddTagDialog";
import { AddTagCommand, EditTagForm } from "@grenton/gm-logic";
import { TAG_SEPARATOR } from "@grenton/gm-logic";
import { useDispatcher } from "../hooks/useDispatcher";
import { EditTagCategoryForm, EditTagCategoryCommand } from "@grenton/gm-logic";
import { AddTagCategoryDialog } from "./AddTagCategoryDialog";
import { useProjectOr } from "../hooks/useProject";
import { TagContext } from "./TagContext";


const EMPTY_FORM = {category:'', value:''}

export function parseTag(tag?: string) {
  const t = tag?.split(TAG_SEPARATOR)||[];
  return { category: t[0]?.trim()||'', value: t[1]?.trim()||'' };
}

export function TagContextProvider({ children }:PropsWithChildren<{}>) {

  const [tagCategories] = useProjectOr(project=>project.tags.categories, [])
  const dispatcher = useDispatcher()
  const [addingNewTag, setAddingNewTag] = useState<EditTagForm|null>(null)
  const [addingCategoryTag, setAddingCategoryTag] = useState(false)


  const createNewTag = (existingTag?: string) => {
    setAddingNewTag(parseTag(existingTag))
  };

  const createNewTagCategory = () => {
    setAddingCategoryTag(true)
  }

  function addTag(form?: EditTagForm): void {
    setAddingNewTag(null)
    if (form) {
        const values = form.value.split('\n').map(value => value.trim()).filter(v => v.length)
        for (const value of values) {
            dispatcher(new AddTagCommand({ form: { category: form.category, value, single: form.single } }))
        }
    }
  }

  function addTagCategory(form?: EditTagCategoryForm): void {
    setAddingCategoryTag(false)
    if (form) {
        dispatcher(new EditTagCategoryCommand({ form }))
    }
  }

  return <TagContext.Provider value={{ createNewTag, createNewTagCategory }}>
      <AddTagDialog 
        open={Boolean(addingNewTag)} 
        meta={{categories:tagCategories}} 
        form={addingNewTag||EMPTY_FORM}
        onAddCategory={()=>setAddingCategoryTag(true)} 
        onClose={addTag}/>

      <AddTagCategoryDialog 
        open={addingCategoryTag}
        form={{ name: '', color: '', multiple: true }}
        onClose={addTagCategory} />
      {children}
    </TagContext.Provider>  
};
