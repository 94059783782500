import { ProjectTreeItemMethodData, ProjectTreeItem, ProjectTreeItemType } from '@grenton/gm/editor/components';
import { MethodImpl, ProjectObjectImpl } from '@grenton/gm-logic';
import { concatTreeItemId } from './id';

export function methodNode(parentItemId: string, path: string[], object: ProjectObjectImpl, method: MethodImpl): ProjectTreeItem<ProjectTreeItemMethodData> {
    const id = concatTreeItemId(parentItemId, method.id);
    return {
        id,
        label: method.name,
        icon: method.spec.scene ? 'scene' : 'method',
        sortKey: method.name,
        data: {
            type: ProjectTreeItemType.METHOD,
            path: path,
            objectId: object.uuid,
            methodId: method.id,
            scene: !!method.spec.scene, // needed?
            hasCode: object.scripts?.scripts[method.spec.id]?.hasContent === true,
        },
        children: [],
    };
}
