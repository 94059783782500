import { ValidatorResult } from '../../../../../../../model-ui';
import { checkLuaName } from '@grenton/gm-logic';
import { ObjectMethodForm } from '@grenton/gm-logic';

export const methodFormValidator = (hasLabel: (label: string) => boolean) => {
    return (form: ObjectMethodForm) => {
        let result = ValidatorResult.validResult;

        const label = form.spec.label?.trim();
        if (!label || label.length === 0) {
            result = result.withError('label', 'name is required');
        } else {
            const luacheck = checkLuaName(label);
            if (luacheck !== 'ok') {
                result = result.withError(
                    'label',
                    luacheck === 'reserved' ? 'this name is reserved' : 'name is invalid (only letters, numbers and underscores are allowed)',
                );
            } else if (hasLabel(label)) {
                result = result.withError('label', 'script with this name already exists in this namespace');
            }
        }

        return result;
    };
};
