import { MoreHoriz} from "@mui/icons-material";
import { ProjectTreeItemType, ProjectTreeItemData, ProjectTreeItem } from "@grenton/gm/editor/components/projectComponentTree";
import { GTreeItemRendererProps, ModuleLabel, TagLabel } from "@grenton/design-system";
import { IconConstructor, icons } from "@grenton/gm/ui/icons";
import React from "react";
import { ActionIconButton, ObjectTreeItemContent, ObjectTreeItemRoot } from "@grenton/gm/shared/components/objectTreeItem";

export function outletObjectSelectorRenderer({item, onClick}: GTreeItemRendererProps<ProjectTreeItem<ProjectTreeItemData>>): JSX.Element {
    
    function content() {
        const data = item.data

        
        switch (data.type) {
            case ProjectTreeItemType.MODULE:
                return <ObjectTreeItemContent label={<ModuleLabel module={data.module}/>} hint={data.module.id}/>
            case ProjectTreeItemType.SPACE:
                return <TagLabel label={data.tag}/>
            case ProjectTreeItemType.OBJECT:
                return <ObjectTreeItemContent label={item.label} greyedOut={!data.assignedToRootTag} action={<ActionIconButton icon={<MoreHoriz/>} onClick={e=>onClick(e,'action')}/>}/>
            default:
                return <ObjectTreeItemContent label={item.label}/>
        }
    }


    let icon:React.ReactNode = null
    if (item.icon) {
        const ResolvedIcon:IconConstructor = icons[item.icon]
        icon = <ResolvedIcon fontSize="inherit"/>
    }

    return <ObjectTreeItemRoot icon={icon} onClick={onClick}>{content()}</ObjectTreeItemRoot>
}

