import { passwordRegExp } from '@grenton/utils';

export const FORM_FIELD = {
    name: {
        required: 'Name is required',
    },
    accessLevel: {
        required: 'Access level is required',
    },
    password: {
        required: 'Provide the password',
        pattern: {
            value: passwordRegExp,
            message: '8-40 characters with upper & lower case, a number, and a special character.',
        },
    },
};
