import {Box} from "@mui/material";
import {OutletObjectSelectorTree} from "../outletObjectSelectorTree";
import type {OutletObjectSelectorProps} from './types';

export function OutletObjectSelector({project, ids, allowedApis, onChange}: OutletObjectSelectorProps) {
    return (
        <Box maxHeight={300}>
            <OutletObjectSelectorTree
                project={project}
                allowedApis={allowedApis}
                onChange={onChange}
                selected={ids}/>
        </Box>
    )
}