import { useCallback } from 'react';
import { useService } from '../../providers';
import { Command } from '@grenton/gm-logic';

export function useDispatcher() {
    const { commandDispatcher } = useService();
    const forwarder = useCallback(
        function <T extends Command>(cmd: T) {
            commandDispatcher.execute(cmd);
        },
        [commandDispatcher],
    );
    return forwarder;
}
