import { GTreeItemRendererProps, ModuleLabel } from "@grenton/design-system"
import { ObjectTreeItemContent, ObjectTreeItemRoot } from "@grenton/gm/shared/components/objectTreeItem"
import { IconConstructor, icons } from "@grenton/gm/ui/icons"
import { CFGTreeItem, CFGTreeItemType } from "./types"
import { DynamicLinkButton } from "../components/DynamicLinkButton"

export function configurationTreeRenderer({item, onClick}: GTreeItemRendererProps<CFGTreeItem>): JSX.Element {
    
    function content() {

        const data = item.data

        switch (data.type) {

            case CFGTreeItemType.COMPONENT: {
                const label = <ModuleLabel module={data.component}/>
                const action = <DynamicLinkButton disabled={item.disabled} belongsTo="project" moduleId={data.component.id} linked={Boolean(data.linkedTo)} onClick={e=>onClick(e,'link')}/>
                // data.linkedTo ? 
                //     <ActionIconToggleButton disabled={item.disabled} selected={Boolean(data.linking)} icon={<CheckBoxOutlined/>} onClick={e=>onClick(e,'unlink')}/> : 
                //     <ActionIconToggleButton disabled={item.disabled} selected={Boolean(data.linking)} icon={<CheckBoxOutlineBlank/>} onClick={e=>onClick(e,'link')}/> 
               
                const hint = data.linkedTo ? `${data.component.id} \u2194 ${data.linkedTo}` : data.component.id
                return <ObjectTreeItemContent label={label} action={action} hint={hint}/>
            }

            case CFGTreeItemType.OBJECT: {
                return <ObjectTreeItemContent label={item.label} hint={data.port}/>
            }
                        
            default:
                return null
                
        }
    }


    let icon:React.ReactNode = null
    if (item.icon) {
        const ResolvedIcon:IconConstructor = icons[item.icon]
        icon = <ResolvedIcon fontSize="inherit"/>
    }


    return <ObjectTreeItemRoot icon={icon} onClick={onClick}>{content()}</ObjectTreeItemRoot>

    

    
}
