import {ReactElement, useState} from "react";
import {Add, Edit, ExpandMore} from "@mui/icons-material";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    IconButton,
    Stack,
    ListItemButton
} from "@mui/material";
import type {CategoryAccordionProps} from "./types";
import {TagLabel} from '@grenton/design-system';
import {ModalCategory} from "../modalCategory";
import {ModalTag} from "../modalTag";
import { useProject } from "@grenton/gm/ui";

export function CategoryAccordion({category, handleAddTag}: CategoryAccordionProps): ReactElement {
    const [project] = useProject();
    const tags = project.tags;
    const categoryData = tags?.getCategory(category?.name || "");
    const tagsInCategory = categoryData?.tags;
    const [editedTag, setEditedTag] = useState<string | undefined>(undefined);
    const [editedCategory, setEditedCategory] = useState<boolean>(false);
    const isTagsInCategory = Boolean(tagsInCategory?.length);

    return (
        <>
            <ModalTag
                isOpen={Boolean(editedTag)}
                onClose={() => setEditedTag(undefined)}
                category={categoryData}
                tag={editedTag}
            />
            <Accordion square={true}>
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                    aria-controls={categoryData?.data?.name}
                    id={categoryData?.data?.name}
                >
                    <TagLabel color={categoryData?.data?.color} label={categoryData?.data?.name||'noname'}/>
                </AccordionSummary>
                <AccordionDetails sx={{padding: 1}}>

                    <Stack direction={"row"} alignItems={"start"} padding={0}>
                        <List sx={{flexGrow: 2, padding: 0}} dense={true}>
                            {isTagsInCategory && tagsInCategory?.map((tag) => (
                                <ListItemButton key={tag} sx={{paddingLeft: 1, gap: 1}} onClick={() => setEditedTag(tag)}>
                                    <TagLabel label={tag}/>
                                </ListItemButton>
                            ))}
                        </List>
                        <ModalCategory
                            isOpen={editedCategory}
                            onClose={() => setEditedCategory(false)}
                            category={category}
                        />
                        <IconButton onClick={() => setEditedCategory(true)}>
                            <Edit/>
                        </IconButton>
                        <IconButton onClick={() => handleAddTag({category})}>
                            <Add/>
                        </IconButton>
                    </Stack>
                </AccordionDetails>
            </Accordion>
        </>
    );
}
