import { Box } from "@mui/material";
import { ProjectTreeItemType } from "../../editor/components";
import { ProjectImpl } from "@grenton/gm-logic";
import LinkMarker from "../components/LinkMarker";
import LinkButton from "../components/LinkButton";
import { SelectedEntity } from "../types";
import type { ProjectTreeItem, ProjectTreeItemData } from "../../editor/components";
import { configurationTreeModel } from "./configurationTreeModel";
import { ConfigurationTree } from "./ConfigurationTree";
import { CFGTreeItem, CFGTreeItemType } from "./types";


//@ts-ignore
function _projectNodeRenderer(
    node: ProjectTreeItem<ProjectTreeItemData>, 
    mode:'module'|'object'|undefined,
    onStartMapping: (node: ProjectTreeItem<ProjectTreeItemData>) => void,
    onDeleteMapping : (uuid:string) => void): JSX.Element {
    return (<Box sx={{ display: 'flex', width:'100%', alignItems: 'center',margin:1 }}>
        <Box sx={{ flexGrow: 1, textOverflow: 'ellipsis', overflow: 'hidden', paddingRight:1 }}>{node.label || node.id}</Box>
        
        { node.data.type === ProjectTreeItemType.MODULE ? 
             (node.data.hw ? 
                <LinkMarker label={`SN ${node.data.hw}`} onDelete={()=>onDeleteMapping((node.data as any).module.uuid)}/>:
                (node.disabled || mode === 'object' ? null : <LinkButton label="Link" selected={node.data.linking} onClick={()=>{onStartMapping(node)}}/>)) 
                : null 
        }
        {
            node.data.type === ProjectTreeItemType.OBJECT ? (!node.disabled && mode !== 'module' ? <LinkButton label={mode === 'object' && !node.data.linking ? '\u21E5' : "Move"} selected={node.data.linking} onClick={()=>{onStartMapping(node)}}/> : <div style={{minWidth:60}}/> ) : null
        }
    </Box>)
}


export function ProjectPane(props: {
    project: ProjectImpl,
    filter?:string,
    hideLinked?:boolean,
    selectedEntity?:SelectedEntity,
    onStartMapping: (node: CFGTreeItem) => void,
    onDeleteMapping : (uuid:string) => void
}) {
    const items = configurationTreeModel(
        props.project,
        props.hideLinked===true,
        props.selectedEntity?.selection === 'module' && props.selectedEntity.belongsTo==='project' ? props.selectedEntity : undefined,
        props.selectedEntity?.selection === 'module' && props.selectedEntity.belongsTo==='hardware' ? props.selectedEntity.type : undefined,
        props.selectedEntity?.selection === 'object' ? props.selectedEntity : undefined,
        )


    return <ConfigurationTree
        defaultExpanded={items.map(n => n.id)}

        items={items} 
        onItemClick={function (e: React.MouseEvent, item: CFGTreeItem, data: any): void {
            if (data) {
                e.stopPropagation()
                if (item.data.type === CFGTreeItemType.COMPONENT) {
                    if (item.data.linkedTo) {
                        props.onDeleteMapping(item.data.component.id)
                    } else {
                        props.onStartMapping(item)
                    }
                }
            }
        }}/>
}
