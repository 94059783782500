import {Box, Card, CardContent, CardHeader, IconButton, MenuItem, Stack, Typography} from "@mui/material";
import {layoutGenerator} from "./layout-generator";
import './widgets';
import {widgetRenderer} from "./renderer";
import {ErrorBoundary} from 'react-error-boundary'
import {MoreHoriz} from "@mui/icons-material";
import WidgetPropsDialog from "./WidgetPropsDialog";
import {WidgetPropsForm, WidgetPropsFormMeta} from "./WidgetPropsFormUI";
import {useContext, useMemo, useState} from "react";
import GMTextField from "@grenton/gm/ui/controls/GMTextField";
import ScreenPropsDialog from "./ScreenPropsDialog";
import {ScreenPropsForm, ScreenPropsFormMeta} from "./ScreenPropsFormUI";
import {ObjectRPCExecutor} from "@grenton/gm-logic";
import {AppLayoutImpl, AppPageImpl, AppPageSectionImpl} from "@grenton/gm/my-grenton/app-layout-impl";
import {SystemModelContext} from "../runtime/system-model-context";
import {AppPage, AppWidget} from "@grenton/gm-logic";
import {ObjectRPCExecutorContext} from "./object-rpc-executor-context";
import { useProject } from "../ui";
import { useService } from "../providers/useService";
import { SystemModelProvider } from "../runtime/SystemModelProvider";

function fallbackRender(props: { error: Error, resetErrorBoundary: any }) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.

    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre style={{color: "red"}}>{props.error.message}</pre>
        </div>
    );
}

function WidgetSlot(props: { children: JSX.Element, onEdit: () => void }) {
    return <ErrorBoundary fallbackRender={fallbackRender}>
        <Box sx={{position: "relative"}}>
            <Box sx={{}}>{props.children}</Box>
            <Box sx={{position: "absolute", top: -8, right: -8}}><IconButton
                onClick={props.onEdit}><MoreHoriz/></IconButton></Box>
        </Box>
    </ErrorBoundary>
}

function AppSectionSlot(props: {
                            section: AppPageSectionImpl,
                            onEditWidget: (widgetId: string) => void
                        }
) {
    const system = useContext(SystemModelContext)
    return <Stack spacing={1}>
        {props.section.title ? <Typography variant="subtitle1">{props.section.title}</Typography> : null}
        {props.section.widgets.map((widget, i) => (
            <WidgetSlot key={i}
                        onEdit={() => props.onEditWidget(widget.id)}>{widgetRenderer.render(widget, system)}</WidgetSlot>
        ))}
    </Stack>
}

function AppPageSlot(props: {
    page: AppPageImpl, onEditScreen: () => void, onEditWidget: (widgetId: string) => void
}) {
    return <Card sx={{position: 'relative', minWidth: 320, minHeight: 480, background: "#eee"}}>
        <Box sx={{position: "absolute", top: 2, right: 2}}><IconButton
            onClick={props.onEditScreen}><MoreHoriz/></IconButton></Box>
        <CardHeader title={props.page.title}/>
        <CardContent>
            <Stack spacing={1}>
                {props.page.sections.map((section, i) => (
                    <AppSectionSlot key={i} section={section} onEditWidget={props.onEditWidget}/>
                ))}
            </Stack>
        </CardContent>
    </Card>
}


function AppPages(props: {
    layout: AppLayoutImpl,
    executor: ObjectRPCExecutor,
    editScreen: (screen: AppPage) => void, editWidget: (widgetId: string) => void
}) {
    return <ObjectRPCExecutorContext.Provider value={props.executor}>
            {props.layout.pages.map((page, r) => (
                <AppPageSlot
                    key={r}
                    page={page}
                    onEditScreen={() => props.editScreen(page.page)}
                    onEditWidget={w => props.editWidget(w)}/>
            ))}
        </ObjectRPCExecutorContext.Provider>
}

export function AppEditor() {
    return <SystemModelProvider>
        <AppEditorInner/>
    </SystemModelProvider>
}

export function AppEditorInner() {

    const systemModel = useContext(SystemModelContext)

    const [project] = useProject()
    const {localFabric} = useService()
    const [widgetPropsDialog, setWidgetPropsDialog] = useState<{
        open: boolean,
        meta?: WidgetPropsFormMeta,
        form?: WidgetPropsForm
    }>({open: false})
    const closeWidgetPropsDialog = () => setWidgetPropsDialog({...widgetPropsDialog, open: false})
    const [screenPropsDialog, setScreenPropsDialog] = useState<{
        open: boolean,
        meta?: ScreenPropsFormMeta,
        form?: ScreenPropsForm
    }>({open: false})
    const closeScreenPropsDialog = () => setScreenPropsDialog({...screenPropsDialog, open: false})

    const [layout, setLayout] = useState(AppLayoutImpl.empty)
    const executor = localFabric.executor
    useMemo(() => {
        if (systemModel) {
            setLayout(AppLayoutImpl.from(layoutGenerator.layout(systemModel)))
        }
    }, [systemModel])



    if (!project) return (<Box>Project not loaded</Box>)


    // const appView = useMemo(()=>new AppSystemViewProjectFacade(project), [project])

    function editWidget(_widget: AppWidget<any>) {
        setWidgetPropsDialog({...widgetPropsDialog, open: true})
    }

    function editScreen(_screen: AppPage) {
        setScreenPropsDialog({...screenPropsDialog, open: true})
    }

    return (<>
       
                <Stack direction={"row"} p={1} alignItems={"center"}>
                    <GMTextField size="small" select value={"auto"} onChange={e => e.target.value}>
                        <MenuItem value="auto">auto</MenuItem>
                        <MenuItem value="custom">custom</MenuItem>
                    </GMTextField>
                    <Box flexGrow={2}/>
                    <Typography>Pair code: <b>{project.pairCode}</b></Typography>

                </Stack>
                <Box flexGrow={2} sx={{overflow: 'auto'}}>
                    <Stack direction="row" margin={2} spacing={2}>
                        {systemModel ? (
                                <AppPages
                                    executor={executor}
                                    editScreen={editScreen}
                                    editWidget={widgetId => editWidget(layout.widgetProvider(widgetId)!)}
                                    layout={layout}/>
                            ) :
                            null}
                    </Stack>
                    <WidgetPropsDialog
                        open={widgetPropsDialog.open}
                        form={{uuid: '', name: ''}} meta={{}}
                        onEdit={(_form: WidgetPropsForm) => {
                        }}
                        onDelete={(_uuid) => {
                        }}
                        onClose={() => closeWidgetPropsDialog()}/>
                    <ScreenPropsDialog
                        open={screenPropsDialog.open}
                        form={{uuid: '', name: ''}} meta={{}}
                        onEdit={(_form: ScreenPropsForm) => {
                        }}
                        onDelete={(_uuid) => {
                        }}
                        onClose={() => closeScreenPropsDialog()}/>
                </Box>
                </>
    )
}
