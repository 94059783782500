import {isApiExtending} from "@grenton/gm-common";
import {objectNodes} from "./objectNodes";
import { ProjectTreeItemData, ProjectTreeItem, ProjectTreeItemType } from "@grenton/gm/editor/components";
import { ProjectImpl, ProjectObjectImpl } from "@grenton/gm-logic";
import { objectIconResolver } from "@grenton/gm/shared";

type Props = {
    project: ProjectImpl;
    allowedApis: string[];
};

export function objectSelectorTreeModel({project, allowedApis}: Props): ProjectTreeItem<ProjectTreeItemData>[] {
    const tags = [...project.tags.all, ""]

    const filterByAllowedApi = (obj: ProjectObjectImpl) => {
        for (const allowedApi of allowedApis) {
            if (isApiExtending(obj.api.api, allowedApi)) return true
        }
        return false
    }

    const nodes = tags.map((tag) => {
        const objects = Object.values(project.objects)
            .filter(object => (tag ? object.tags.includes(tag) : object.tags.empty))

       // const t = tag.split(':')
        const id = `tag:${tag}`
        return {
            id,
            label: `${tag} TODO fmt`,/*<Stack direction="row" sx={{alignItems: 'center'}}>
                <TagLabel label={t[0]||'noname'} color={project.tags.getColor(t[0])}/>
                {t[1]}
            </Stack>,*/
            icon:null,
            sortKey: tag,
            data: {
                type: ProjectTreeItemType.SPACE as const,
                tag
            },
            children: [...objectNodes(id, objects, filterByAllowedApi, objectIconResolver(project.firmware))]
        }
    }).filter(node => node.children.length)

    return nodes
}
